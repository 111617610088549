import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import Header from './../../6.header/index';
import { useHistory } from 'react-router-dom';
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ErrorMessage from '../../../../package/parts/error';
import BackModal from './../../11.back/index';

const WorksEdit = () => {
  
  const history = useHistory();

  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  const [ backStatus, setBackStatus ] = useState(false);

  const [ errorMessage, setErrorMessage ] = useState('');
  
  const [ addList, setAddList ] = useState([]);
  const [ allList, setAllList ] = useState([]);

  const [ storeList, setStoreList ] = useState([]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        getRecruits();
      } else {
        history.push('/company/login');
      }
    });
  }, [history]);

  function getRecruits() {

    firebase.firestore().collection('stores').where('companyId', '==', company).orderBy('number', 'asc').get()
    .then(snapShot => {
      var tmp = [];
      var list = [];
      var addlist = [];
      snapShot.forEach((doc) => {
        if (doc.data().recruitId === '' || doc.data().recruitId === recruit) {
          console.log('doc.id');
          tmp.push({ ...doc.data(), id: doc.id });
          list.push(doc.id);
          if (doc.data().recruitId === recruit) {
            addlist.push(doc.id);
          }
        }
      });
      console.log(tmp);
      setStoreList(tmp);
      setAllList(list);
      setAddList(addlist);
    });
  }

  function check(v) {
    if (addList.length === 0) {
      setErrorMessage('店舗を追加してください。');
    } else {
      deleteList(v);
    }
  }

  function deleteList(v) {
    for (var i = 0; i < allList.length; i++) {
      firebase.firestore().collection("stores").doc(allList[i])
      .update({
        recruitId: '',
      });
    }
    save(v);
  }

  function save(v) {
    for (var i = 0; i < addList.length; i++) {
      firebase.firestore().collection("stores").doc(addList[i])
      .update({
        recruitId: recruit,
      });
    }

    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      place_stores: addList,
      status_place: v,
    }).then(() => {
      history.push(`/edit/id=${company}+page=2+recruitId=${recruit}`);
    });
  }

  function pushStyle(value) {
    setAddList([...addList, value]);
  }

  return (
    <>
    
    <Header />

    <div className={Styles.box}>

      {errorMessage !== '' ?
        <ErrorMessage message={errorMessage} />
      : <></> }

      <h4 className={Styles.store_title}>店 舗 選 択（{storeList.length}件）</h4>

      <div className={Styles.store_list}>
        {storeList.map((data, index) => 
          <div className={Styles.store_list_items} key={index}>
            <input type="checkbox"
              checked={addList.indexOf(data.id) !== -1 ? 'checked' : ''}
              onChange={(e) => e.target.checked ? pushStyle(data.id) : setAddList(addList.filter((v, index) => (v !== data.id)))}/>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/stores%2Fresize_images%2F${data.image}_500x500?alt=media&token=?${new Date().getTime()}`} />
            <h4>{data.name}</h4>
          </div>
        )}
      </div>

      <div className={Styles.save_box}>
        <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
        <button className={Styles.save_box_now} onClick={() => check(false)}>一時保存</button>
        <button className={Styles.save_box_save} onClick={() => check(true)}>保存</button>
      </div>

    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={recruit}
        setBackStatus={setBackStatus}
      />
    : <></> }
    </>
  );
}

export default WorksEdit;
