import Styles from './styles.module.css';
import 'firebase/auth'
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { useHistory } from 'react-router-dom';
import DefaultTitle from '../../../../package/parts/title/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../../11.back/index';
import Header from '../../6.header/index';
import DefaultSlider from '../../../../package/parts/slider';

const MemoriiWorksEditRecruit = (props) => {

  const history = useHistory();

  // 雰囲気
  const [ person1, setPerson1 ] = useState(1);
  const [ person2, setPerson2 ] = useState(1);
  const [ person3, setPerson3 ] = useState(1);
  const [ person4, setPerson4 ] = useState(1);
  const [ person5, setPerson5 ] = useState(1);
  
  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));
    
  useEffect(() => {
    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {

        firebase.firestore().collection("recruits").doc(recruit)
        .get().then((doc) => {
          
          setPerson1(doc.data().recruit_person1 === undefined ? 3 : doc.data().recruit_person1);
          setPerson2(doc.data().recruit_person2 === undefined ? 3 : doc.data().recruit_person2);
          setPerson3(doc.data().recruit_person3 === undefined ? 3 : doc.data().recruit_person3);
          setPerson4(doc.data().recruit_person4 === undefined ? 3 : doc.data().recruit_person4);
          setPerson5(doc.data().recruit_person5 === undefined ? 3 : doc.data().recruit_person5);

        });
      } else {
        history.push('/company/login')
      }
    })

  }, [history, recruit])

  function check() {
    window.scrollTo(0, 0);

    save(true);
  }

  function save(e) {

    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      status_person: e,

      recruit_person1: Number(person1),
      recruit_person2: Number(person2),
      recruit_person3: Number(person3),
      recruit_person4: Number(person4),
      recruit_person5: Number(person5),

      last_time: new Date(),
    }).then(() => {
      history.push(`/edit/id=${company}+page=2+recruitId=${recruit}`);
    });
  }

  const [ backStatus, setBackStatus ] = useState(false);

  return (
    <>

    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        <div className={Styles.contents}>
          <h5 className={Styles.title}>基本情報</h5>
          
          <div className={Styles.store_contents}>

            <div>
              <DefaultTitle title={'行動指針'} required={true} />
              <DefaultSlider
                range1={'まずは考える'}
                range2={'とにかく行動'}
                value={person1} setValue={setPerson1}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'施術スタイル'} required={true} />
              <DefaultSlider
                range1={'オールマイティー'}
                range2={'専門性'}
                value={person2} setValue={setPerson2}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'重視する項目'} required={true} />
              <DefaultSlider
                range1={'再現性'}
                range2={'創造性'}
                value={person3} setValue={setPerson3}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'キャリアプラン'} required={true} />
              <DefaultSlider
                range1={'チームリーダー'}
                range2={'トッププレイヤー'}
                value={person4} setValue={setPerson4}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'アピールポイント'} required={true} />
              <DefaultSlider
                range1={'コミュニケーション重視'}
                range2={'技術重視'}
                value={person5} setValue={setPerson5}
              />
              <p className={Styles.desc}></p>

            </div>
          </div>

        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>
        
      </div>
    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={recruit}
        setBackStatus={setBackStatus}
      />
    : <></> }
    </>
  );
}

export default MemoriiWorksEditRecruit;