import React from 'react';
import Styles from './styles.module.css';

const Footer = () => {

  var list = [
    
  ];

  return (
    <>
    <table>
      <tr>
        <th className={Styles.table8}>カット価格</th>
        <th className={Styles.table9}>席数</th>
        <th className={Styles.table10}>スタッフ数</th>
        <th className={Styles.table11}>こだわり条件</th>
      </tr>
      {list.map((data, index) =>
        <>
          {data.length === 5 ?
            <tr >
              <td className={Styles.table11}>{data[0]}</td>
              <td className={Styles.table12}>{data[1]}</td>
              <td className={Styles.table13}>{data[2]}</td>
              <td className={Styles.table14}>{data[3]}</td>
              <td className={Styles.table14}>{data[4]}</td>
            </tr>
            :
            <tr >
              <td className={Styles.table11}>　</td>
              <td className={Styles.table12}>{data[0]}</td>
              <td className={Styles.table13}>{data[1]}</td>
              <td className={Styles.table14}>{data[2]}</td>
              <td className={Styles.table14}>{data[3]}</td>
            </tr>
          }
        </>
      )}

    </table>
    </>
  );
}

export default Footer;
