import DocumentMeta from 'react-document-meta';
import './styles.css';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { onSnapshot, collection, query, where, orderBy, } from 'firebase/firestore';
import Header from './0.header/index';
import Navigation from './navigation/index';
import Home from './1.home/index';
import Recruit from './2.recruit/index';
import Photo from './3.photo/index';
import Manage from './4.manage/index';
import Schedule from './5.schedule/index';
import Message from './6.contact/index';
import Setting from './7.setting/index';
import { useHistory } from 'react-router-dom';
import { isMobile } from "react-device-detect";

const MemoriiWorksService = () => {

  const history = useHistory();
  
  const [ navigation, setNavigation ] = useState(1);

  const [ userList, setUserList ] = useState([]);
  const [ companyList, setCompanyList ] = useState([]);
  const [ recruitList, setRecruitList ] = useState([]);
  const [ recruitListOnlyId, setRecruitListOnlyId ] = useState([]);

  const [ authority, setAuthority ] = useState(false);

  const [ profileSwitch, setProfileSwitch ] = useState(false);
  const [ companySwitch, setCompanySwitch ] = useState(false);

  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var nav = decodeURIComponent(link.slice(link.indexOf('+page') + 6, link.indexOf('+page') + 7));

  useEffect(() => {
    setNavigation(Number(nav));
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        getUsers(user.uid);
        getComapnay(user.uid, Number(nav));
        getRecruits();
      } else {
        history.push('/company/login')
      }
    });
  }, [ link ]);

  function getUsers(uid) {
    const docRef = firebase.firestore().doc(`users/${uid}`)
    const unsub = onSnapshot(docRef, doc => {
      setUserList({...doc.data(), id: doc.id});
    })
    return () => unsub();
  }

  function getComapnay(uid, nav) {    
    const docRef = firebase.firestore().collection('companies').doc(company)
    const unsub = onSnapshot(docRef, doc => {
      setCompanyList({...doc.data(), id: doc.id});

      if (doc.data().administrator.includes(uid)) {
        setAuthority(true);
      } else if (doc.data().member.includes(uid)) {
        if (nav === 4 || nav === 5) {
        } else {
          history.push(`/service/id=${doc.id}+page=4`);
        }
      } else {
        history.push('/company/login')
      }

      if (isMobile) {
        if (nav === 4 || nav === 5) {
          setNavigation(nav);
        } else {
          setNavigation(4);
        }
      }
    })
    return () => unsub();
  }

  function getRecruits() {
    setRecruitList([]);
    setRecruitListOnlyId([]);
    const docRef = collection(firebase.firestore(), 'recruits')
    const queryRef = query(docRef, where('companyId', '==', company))
    const queryRefs = query(queryRef, orderBy('last_time', 'desc'))
    const unsub = onSnapshot(queryRefs, snapshot => {
      var tmp = [];
      var ids = [];
      snapshot.docs.forEach(doc => {
        tmp.push({...doc.data(), id: doc.id});
        ids.push(doc.id);
      })
      setRecruitList(tmp);
      setRecruitListOnlyId(ids);
    })
    return () => unsub();
  }

  const meta = {
    name: 'robots',
    content: 'noindex',
  };

  return (
    <>
    <DocumentMeta {...meta}>
      {/* z-index 100 */}
      {userList.length !== 0 && companyList.length !== 0 ?
        <Header
          userList={userList}
          companyList={companyList}
          profileSwitch={profileSwitch}
          setProfileSwitch={setProfileSwitch}
          companySwitch={companySwitch}
          setCompanySwitch={setCompanySwitch}
        />
      : <></> }
      
      {/* z-index 1000 */}
      <div onClick={() => {setProfileSwitch(false); setCompanySwitch(false);}}>
        {userList.length !== 0 && companyList.length !== 0 ?
          <Navigation
            navigation={navigation}
            userList={userList}
            companyList={companyList}
            setNavigation={setNavigation}
            authority={authority}
          />
        : <></> }
      </div>
      
      <div onClick={() => {setProfileSwitch(false); setCompanySwitch(false);}}>
        {userList.length !== 0 && companyList.length !== 0 ?
        <>
          {navigation === 1 && authority && !isMobile ?
            <Home userList={userList} companyList={companyList} recruitList={recruitList} />
              : navigation === 2 && authority && !isMobile ?
            <Recruit userList={userList} companyList={companyList} recruitList={recruitList} />
              : navigation === 3 && authority && !isMobile ?
            <Photo userList={userList} companyList={companyList} />
              : navigation === 4 ?
            <Manage userList={userList} companyList={companyList} recruitList={recruitList} recruitListOnlyId={recruitListOnlyId} />
              : navigation === 5 ?
            <Schedule userList={userList} companyList={companyList} />
              : navigation === 6 && authority && !isMobile ?
            <Message userList={userList} companyList={companyList} recruitList={recruitList} recruitListOnlyId={recruitListOnlyId} />
              : navigation === 7 && authority && !isMobile ?
            <Setting userList={userList} companyList={companyList} />
            : <></>
          }
        </> : <></> }
      </div>
    </DocumentMeta>
    </>
  );
}

export default MemoriiWorksService;
