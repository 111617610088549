import 'firebase/auth'
import React from 'react-router-dom';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import liff from "@line/liff";

const MemoriiWorksSetting = () => {

  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('state=') + 6, link.length));

  const history = useHistory();

  useEffect(() => {

    liff
    .init({
      liffId: '2006500323-aKDEKRzl',
      withLoginOnExternalBrowser: true,
    })
    .then(() => {

      liff.ready.then(() => {
        if (liff.isLoggedIn()) {
          liff
          .getProfile()
          .then((profile) => {
            console.log(profile);
            return writeLineId(profile.userId, profile.pictureUrl, profile.displayName);
          })
          .catch((error) => {
          });
        } else {
          console.log('no');
        }
      });
    })
    .catch(() => {
      console.log("init failed");
    });
  }, []);

  function writeLineId(id, url, name) {
    console.log(id, url, name)

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.firestore().collection('companies').doc(company)
        .update({
          user_lineId: id,
          user_linePicture: url,
          user_lineName: name,
        });

        firebase.firestore().collection('users').doc(user.uid)
        .update({
          user_lineId: id,
          user_linePicture: url,
          user_lineName: name,
        })
        .then(() => {
          console.log('ここに来てる？')
          history.push(`/service/id=${company}+page=7`);
        });
      } else {
        history.push('/company/login')
      }
    });
  }

  // https://memorii-works.com/service/callback?code=CjEmJoyeCNa0KHSxtIXi&state=BQT189YE4d72&liffClientId=2006500323&liffRedirectUri=https%3A%2F%2Fmemorii-works.com%2Fservice%2Fcallback
  
  return (
    <>
    <h4></h4>
    </>
  );
}

export default MemoriiWorksSetting;