import Styles from './styles.module.css';
import React, { useState } from 'react'
import firebase from 'firebase/compat/app';
import { v4 as uuid } from 'uuid';
import DefaultInput1 from '../../package/parts/1-input';
import Image1 from './../../../../image/works/make/1.png';
import Image2 from './../../../../image/works/make/2.png';
import Image3 from './../../../../image/works/make/3.png';
import Image4 from './../../../../image/works/make/4.png';
import Image5 from './../../../../image/works/make/5.png';
import Image6 from './../../../../image/works/make/6.png';

const MemoriiWorksRecruitMakeRecruit = (props) => {

  const [ step, setStep ] = useState(1);
  const [ recruitTitle, setRecruitTitle ] = useState('');
  const [ errorRecruitTitle, setErrorRecruitTitle ] = useState(false);
  
  const [ choicePost, setChoicePost ] = useState(1);
  const [ errorEmployment, setErrorEmployment ] = useState(false);

  function make() {
    var docId = uuid();

    firebase.firestore().collection('recruits').doc(docId)
    .set({
      // 情報
      id: docId,
      companyId: props.companyList.id,
      // folder_id: props.companyList.name,
      time: new Date(),
      last_time: new Date(),

      // データ
      data_applicants: [],
      data_apply: [],
      data_recruitment: [],
      data_pv: [],
      data_save: [],
      data_visit: [],

      // ステータス
      status: 3,
      status_area: false,

      status_store: false,
      status_place: false,
      status_point: false,
      status_number: false,
      status_image: false,
      status_interview: false,
      status_person: false,
      status_recruit: false,
      status_hashTags: false,
      status_document: false,

      // 店舗情報
      // 店舗情報 > 基本
      store_title: recruitTitle,
      store_goalPeople : 0,
      store_store_phoneNumber: '',
      store_store_homepage: '',
      // 店舗情報 > 情報
      store_imageName: [],
      store_PrPoint: '',

      // 店舗情報 > ハッシュタグ
      store_hashTagList: [],
      store_styleTag: '',

      // 求人
      recruit_employmentStatus: choicePost === 1 ? '新卒採用（正社員）' : choicePost === 2 ? '中途採用（正社員）' : choicePost === 3 ? '中途採用（契約社員）' : choicePost === 4 ? 'アルバイト・パート採用' : choicePost === 5 ? '業務委託（フリーランス）' : 'インターンシップ',
      recruit_post: '',
      recruit_salary: '',
      recruit_annualSalaryStart: 0,
      recruit_annualSalaryEnd: 0,
      recruit_annualIncomeStart: 0,
      recruit_annualIncomeEnd: 0,
      recruit_monthlySalaryStart: 0,
      recruit_monthlySalaryEnd: 0,
      recruit_dailyWageStart: 0,
      recruit_dailyWageEnd: 0,
      recruit_hourWageStart: 0,
      recruit_hourWageEnd: 0,
      recruit_successFeeStart: 0,
      recruit_successFeeEnd: 0,
      recruit_workingHoursStart: '',
      recruit_workingHoursEnd: '',
      recruit_successFee: '',
      recruit_overtime: false,
      recruit_overtimeOther: '',
      recruit_holiday: [],
      recruit_holidayCheck: false,
      recruit_holidayOther: '',
      recruit_welfare: [],
      recruit_welfareCheck: false,
      recruit_welfareOther: '',
      recruit_salaryWelfare: '',
      
      recruit_personStatue: '',
      recruit_workContents: '',

      // 勤務場所
      place_storeCount: 0,
      place_stationTags: [],

      // 必須書類
      document_document: false,

      // 評価
      exam_selection: '',
    })
    
    firebase.firestore().collection('companies').doc(props.companyList.id)
    .update({
      recruits: firebase.firestore.FieldValue.arrayUnion(docId)
    }).then(() => props.setMakeDoc(0));

  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => props.setMakeDoc(0)}></div>
        <div className={Styles.modal_contents}>
          <div className={Styles.modal_contents_title}>
            <h4>求人タイトルを入力する</h4>
            <button onClick={() => recruitTitle === '' || recruitTitle.length > 40 ? setErrorRecruitTitle(true) : make()}>作成</button>
          </div>
          <p>{errorRecruitTitle ? 
            <span style={recruitTitle === '' ? { color : '#AD0200', fontWeight : 'bold' } : {}}
          >{recruitTitle === '' ? '※求人タイトルを入力してください。' : '求人タイトルは30文字以内で入力してください。'}</span> : 'ステップ 1/1'} </p>
          <DefaultInput1
            title={'求人タイトル'} required={true} length={30} place={'例）美容業界を変えたい人を募集！'} type={'text'}
            value={recruitTitle} setValue={setRecruitTitle}
            desc={'　'}
          />
          
          <div className={Styles.example}>
            <div className={Styles.example_title}>求人タイトルの参考例</div>
            <div className={Styles.example_contents}>
              {choicePost === 1 ?
              <>
                <div>
                  <p>01. ★高給与と手厚い保証★初任給22万円～【全国253店舗】</p>
                  <button onClick={() => setRecruitTitle('★高給与と手厚い保証★初任給22万円～【全国253店舗】')}>使用する</button>
                </div>
                <div>
                  <p>02. 【24卒選考スタート！】プライベートも充実できるサロン★</p>
                  <button onClick={() => setRecruitTitle('【24卒選考スタート！】プライベートも充実できるサロン★')}>使用する</button>
                </div>
                <div>
                  <p>03. しっかり学んで休んで早期デビュー◎【月給２2万～＋週休２日】</p>
                  <button onClick={() => setRecruitTitle('しっかり学んで休んで早期デビュー◎【月給２2万～＋週休２日】')}>使用する</button>
                </div>
                <div>
                  <p>04. 新店舗オープン！アカデミー/メイク着付け/給与22万社保完備</p>
                  <button onClick={() => setRecruitTitle('新店舗オープン！アカデミー/メイク着付け/給与22万社保完備')}>使用する</button>
                </div>
                <div>
                  <p>05. 【限定3名募集】池袋★カラー/エクステ/ブリーチに特化！</p>
                  <button onClick={() => setRecruitTitle('【限定3名募集】池袋★カラー/エクステ/ブリーチに特化！')}>使用する</button>
                </div>
                <div>
                  <p>06. 【原宿・吉祥寺】デザインカラーに特化したスペシャリスト集団！</p>
                  <button onClick={() => setRecruitTitle('【原宿・吉祥寺】デザインカラーに特化したスペシャリスト集団！')}>使用する</button>
                </div>
                <div>
                  <p>07. デビュー1年以内★営業内練習！社保完備！ウィッグ2体支給</p>
                  <button onClick={() => setRecruitTitle('デビュー1年以内★営業内練習！社保完備！ウィッグ2体支給')}>使用する</button>
                </div>
                <div>
                  <p>08. ★24年卒★「都心に近い地域密着サロン」で美容師しませんか？</p>
                  <button onClick={() => setRecruitTitle('★24年卒★「都心に近い地域密着サロン」で美容師しませんか？')}>使用する</button>
                </div>
              </>
              : choicePost === 2 ?
              <>
                <div>
                  <p>01. ★吉祥寺・池袋・柏★選べる保障/週休2日35万円！★西新井☆</p>
                  <button onClick={() => setRecruitTitle('★吉祥寺・池袋・柏★選べる保障/週休2日35万円！★西新井☆')}>使用する</button>
                </div>
                <div>
                  <p>02. 新規フリー客1000名★平均客単価9,200円★月40万保証</p>
                  <button onClick={() => setRecruitTitle('新規フリー客1000名★平均客単価9,200円★月40万保証')}>使用する</button>
                </div>
                <div>
                  <p>03. 【積極採用中】美容師が最後に辿り着く究極サロン！</p>
                  <button onClick={() => setRecruitTitle('【積極採用中】美容師が最後に辿り着く究極サロン！')}>使用する</button>
                </div>
                <div>
                  <p>04. 【正社員募集】美容大好き！だからイレブンカットが大好き！</p>
                  <button onClick={() => setRecruitTitle('【正社員募集】美容大好き！だからイレブンカットが大好き！')}>使用する</button>
                </div>
                <div>
                  <p>05. 月給35万～＋歩合50％！週休2日（土日休みOK）社保完備◎</p>
                  <button onClick={() => setRecruitTitle('月給35万～＋歩合50％！週休2日（土日休みOK）社保完備◎')}>使用する</button>
                </div>
                <div>
                  <p>06. ★保障給30万円★髪質改善×高単価サロン★社保完★産休育休★</p>
                  <button onClick={() => setRecruitTitle('★保障給30万円★髪質改善×高単価サロン★社保完★産休育休★')}>使用する</button>
                </div>
                <div>
                  <p>07. 新規フリー多数★AWARD受賞★安定した美容師LIFE♪♪</p>
                  <button onClick={() => setRecruitTitle('新規フリー多数★AWARD受賞★安定した美容師LIFE♪♪')}>使用する</button>
                </div>
                <div>
                  <p>08. 月間新規平均400名越え！相模原・町田エリア展開サロン♪</p>
                  <button onClick={() => setRecruitTitle('月間新規平均400名越え！相模原・町田エリア展開サロン♪')}>使用する</button>
                </div>
              </>
              : choicePost === 3 ?
              <>
                <div>
                  <p>01. 60%還元&ゴールドプライズサロンで更に売上UP★</p>
                  <button onClick={() => setRecruitTitle('60%還元&ゴールドプライズサロンで更に売上UP★')}>使用する</button>
                </div>
                <div>
                  <p>02. 週休(3日30万/2日35万/1日50万)指名100%還元</p>
                  <button onClick={() => setRecruitTitle('週休(3日30万/2日35万/1日50万)指名100%還元')}>使用する</button>
                </div>
                <div>
                  <p>03. ★プライズ受賞サロン★17時退社★wワークOK★高単価★</p>
                  <button onClick={() => setRecruitTitle('★プライズ受賞サロン★17時退社★wワークOK★高単価★')}>使用する</button>
                </div>
                <div>
                  <p>04. Award受賞:星1:︎自由出勤×高単価×大人気ハイトーンサロン＊</p>
                  <button onClick={() => setRecruitTitle('Award受賞:星1:︎自由出勤×高単価×大人気ハイトーンサロン＊')}>使用する</button>
                </div>
                <div>
                  <p>05. 【スタイリスト募集】週1日〜、3時間〜、WワークOK</p>
                  <button onClick={() => setRecruitTitle('【スタイリスト募集】週1日〜、3時間〜、WワークOK')}>使用する</button>
                </div>
                <div>
                  <p>06. 10年以上ブランク可　復帰研修3ヶ月　最新技術学べます</p>
                  <button onClick={() => setRecruitTitle('10年以上ブランク可　復帰研修3ヶ月　最新技術学べます')}>使用する</button>
                </div>
                <div>
                  <p>07. NewOpen✗高単価追求★時短・週1〜勤務、土日休みも可★</p>
                  <button onClick={() => setRecruitTitle('NewOpen✗高単価追求★時短・週1〜勤務、土日休みも可★')}>使用する</button>
                </div>
                <div>
                  <p>08. 6月待望のNEW OPEN★賞与実績4ヶ月分★話題のサロン★</p>
                  <button onClick={() => setRecruitTitle('6月待望のNEW OPEN★賞与実績4ヶ月分★話題のサロン★')}>使用する</button>
                </div>
              </>
              : choicePost === 4 ?
              <>
                <div>
                  <p>01. 1,202円～2,025円♪　土日休み相談にのります☆</p>
                  <button onClick={() => setRecruitTitle('1,202円～2,025円♪　土日休み相談にのります☆')}>使用する</button>
                </div>
                <div>
                  <p>02. 時給1500円～★週2日～平日のみも大歓迎★女性スタッフ8割</p>
                  <button onClick={() => setRecruitTitle('時給1500円～★週2日～平日のみも大歓迎★女性スタッフ8割')}>使用する</button>
                </div>
                <div>
                  <p>03. ダブルワークも歓迎！週２日～OK/希望曜日・時間で働けます</p>
                  <button onClick={() => setRecruitTitle('ダブルワークも歓迎！週２日～OK/希望曜日・時間で働けます')}>使用する</button>
                </div>
                <div>
                  <p>04. ≪アルバイト･パート希望者≫ ライフスタイルを応援します！</p>
                  <button onClick={() => setRecruitTitle('≪アルバイト･パート希望者≫ ライフスタイルを応援します！')}>使用する</button>
                </div>
                <div>
                  <p>05. 【パパママ応援サロン】★17時半完全閉店★時給1600円～</p>
                  <button onClick={() => setRecruitTitle('【パパママ応援サロン】★17時半完全閉店★時給1600円～')}>使用する</button>
                </div>
                <div>
                  <p>06. 1日2時間からでも◎土日休みもＯＫ！子育て美容師さん活躍中♪</p>
                  <button onClick={() => setRecruitTitle('1日2時間からでも◎土日休みもＯＫ！子育て美容師さん活躍中♪')}>使用する</button>
                </div>
                <div>
                  <p>07. パートさんも社員保証★時短社員★夕方６時までで24万円支給！</p>
                  <button onClick={() => setRecruitTitle('パートさんも社員保証★時短社員★夕方６時までで24万円支給！')}>使用する</button>
                </div>
                <div>
                  <p>08. パートさんがスタイリストとして活躍できる環境☆1日3 hも可</p>
                  <button onClick={() => setRecruitTitle('パートさんがスタイリストとして活躍できる環境☆1日3 hも可')}>使用する</button>
                </div>
              </>
              : choicePost === 5 ?
              <>
                <div>
                  <p>01. 【大歓迎!!初月報酬40万～50万円♪】インボイス対応♪</p>
                  <button onClick={() => setRecruitTitle('【大歓迎!!初月報酬40万～50万円♪】インボイス対応♪')}>使用する</button>
                </div>
                <div>
                  <p>02. 一律50%還元or週休3日30万♪完全自由出勤×WワークOK</p>
                  <button onClick={() => setRecruitTitle('一律50%還元or週休3日30万♪完全自由出勤×WワークOK')}>使用する</button>
                </div>
                <div>
                  <p>03. 【歩合70％｜日給保証1.7万円】稼げるトレンドサロン</p>
                  <button onClick={() => setRecruitTitle('【歩合70％｜日給保証1.7万円】稼げるトレンドサロン')}>使用する</button>
                </div>
                <div>
                  <p>04. 【HP silver prize受賞★集客大好調】独立支援◎</p>
                  <button onClick={() => setRecruitTitle('【HP silver prize受賞★集客大好調】独立支援◎')}>使用する</button>
                </div>
                <div>
                  <p>05. 新店舗オープン決定!! 歩合50%×材料費会社負担×自由出勤</p>
                  <button onClick={() => setRecruitTitle('新店舗オープン決定!! 歩合50%×材料費会社負担×自由出勤')}>使用する</button>
                </div>
                <div>
                  <p>06. 《最大1年間の保証給50%×選べる働き方♪》顧客0でも安心◎</p>
                  <button onClick={() => setRecruitTitle('《最大1年間の保証給50%×選べる働き方♪》顧客0でも安心◎')}>使用する</button>
                </div>
                <div>
                  <p>07. 【美容師働き方改革】アナタらしく働ける環境がココにある</p>
                  <button onClick={() => setRecruitTitle('【美容師働き方改革】アナタらしく働ける環境がココにある')}>使用する</button>
                </div>
                <div>
                  <p>08. 佐世保中央徒歩3分！働き方を変えて夢を実現しましょう★</p>
                  <button onClick={() => setRecruitTitle('佐世保中央徒歩3分！働き方を変えて夢を実現しましょう★')}>使用する</button>
                </div>
              </>
            : <></> }
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksRecruitMakeRecruit;


