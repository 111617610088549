import Styles from './styles.module.css';
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../6.header/index';
import firebase from "firebase/compat/app"
import ErrorMessage from '../../../../package/parts/error';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import DefaultInput1 from '../../../../package/parts/1-input';
import DefaultTextarea1 from '../../../../package/parts/1-textarea';
import { v4 as uuid } from 'uuid';
import DefaultImage from './../../../../../../image/works/wait.png';
import { ImCross } from "react-icons/im";
import BackModal from '../../11.back/index';
import DefaultTitle from '../../../../package/parts/title';

const EditStore = () => {

  
  const history = useHistory();

  const [ imageCount, setImageCount ] = useState('');

  // リンク
  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  var time = new Date();

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ image1Title, setImage1Title ] = useState('');
  const [ image1Desc, setImage1Desc ] = useState('');

  const [ image2Title, setImage2Title ] = useState('');
  const [ image2Desc, setImage2Desc ] = useState('');

  const [ image3Title, setImage3Title ] = useState('');
  const [ image3Desc, setImage3Desc ] = useState('');

  // 写真
  const [ image1Before, setImage1Before ] = useState([]);
  const [ image1Files, setImage1Files ] = useState([]);
  const [ image1FilesName, setImage1FilesName ] = useState([]);
  const [ image1FilePaths, setImage1FilePaths ] = useState([]);

  // 写真
  const [ image2Before, setImage2Before ] = useState([]);
  const [ image2Files, setImage2Files ] = useState([]);
  const [ image2FilesName, setImage2FilesName ] = useState([]);
  const [ image2FilePaths, setImage2FilePaths ] = useState([]);

  // 写真
  const [ image3Before, setImage3Before ] = useState([]);
  const [ image3Files, setImage3Files ] = useState([]);
  const [ image3FilesName, setImage3FilesName ] = useState([]);
  const [ image3FilePaths, setImage3FilePaths ] = useState([]);

  // 戻るボタン
  const [ backStatus, setBackStatus ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (company !== '' && recruit !== '') {
          firebase.firestore().collection("recruits").doc(recruit).get()
          .then((doc) => {

            // 店舗情報 > 紹介
            setImageCount(doc.data().recruit_imageCount === undefined ? '' : doc.data().recruit_imageCount);

            setImage1Title(doc.data().recruit_image1Title === undefined ? '' : doc.data().recruit_image1Title);
            setImage1Desc(doc.data().recruit_image1Desc === undefined ? '' : doc.data().recruit_image1Desc);

            setImage1Before(doc.data().recruit_image1FilesName === undefined ? [] : doc.data().recruit_image1FilesName)
            setImage1Files(doc.data().recruit_image1FilesName === undefined ? [] : doc.data().recruit_image1FilesName);
            setImage1FilesName(doc.data().recruit_image1FilesName === undefined ? [] : doc.data().recruit_image1FilesName);
            setImage1FilePaths(doc.data().recruit_image1FilesName === undefined ? [] : doc.data().recruit_image1FilesName);

            // 店舗情報 > 紹介
            setImage2Title(doc.data().recruit_image2Title === undefined ? '' : doc.data().recruit_image2Title);
            setImage2Desc(doc.data().recruit_image2Desc === undefined ? '' : doc.data().recruit_image2Desc);
            
            setImage2Before(doc.data().recruit_image2FilesName === undefined ? [] : doc.data().recruit_image2FilesName)
            setImage2Files(doc.data().recruit_image2FilesName === undefined ? [] : doc.data().recruit_image2FilesName);
            setImage2FilesName(doc.data().recruit_image2FilesName === undefined ? [] : doc.data().recruit_image2FilesName);
            setImage2FilePaths(doc.data().recruit_image2FilesName === undefined ? [] : doc.data().recruit_image2FilesName);

            // 店舗情報 > 紹介
            setImage3Title(doc.data().recruit_image3Title === undefined ? '' : doc.data().recruit_image3Title);
            setImage3Desc(doc.data().recruit_image3Desc === undefined ? '' : doc.data().recruit_image3Desc);

            setImage3Before(doc.data().recruit_image3FilesName === undefined ? [] : doc.data().recruit_image3FilesName)
            setImage3Files(doc.data().recruit_image3FilesName === undefined ? [] : doc.data().recruit_image3FilesName);
            setImage3FilesName(doc.data().recruit_image3FilesName === undefined ? [] : doc.data().recruit_image3FilesName);
            setImage3FilePaths(doc.data().recruit_image3FilesName === undefined ? [] : doc.data().recruit_image3FilesName);
          });
        }
      } else {
        history.push('/company/login')
      }
    })
  }, [history, company, recruit]);

  function check() {
    window.scrollTo(0, 0);

    if (Number(imageCount) === 1) {
      if (image1Files.length === 0) {
        setErrorMessage('No.1 アピールの写真を1枚以上添付してください。');
      } else if (image1Title === '') {
        setErrorMessage('No.1 アピールのタイトルを入力してください。');
      } else if (image1Desc === '') {
        setErrorMessage('No.1 アピールの詳細を入力してください。');
      } else {
        save(true);
      }
    }

    if (Number(imageCount) === 2) {
      if (image1Files.length === 0) {
        setErrorMessage('No.1 アピールの写真を1枚以上添付してください。');
      } else if (image1Title === '') {
        setErrorMessage('No.1 アピールのタイトルを入力してください。');
      } else if (image1Desc === '') {
        setErrorMessage('No.1 アピールの詳細を入力してください。');
      } else if (image2Files.length === 0) {
        setErrorMessage('No.2 アピールの写真を1枚以上添付してください。');
      } else if (image2Title === '') {
        setErrorMessage('No.2 アピールのタイトルを入力してください。');
      } else if (image2Desc === '') {
        setErrorMessage('No.2 アピールの詳細を入力してください。');
      } else {
        save(true);
      }
    }

    if (Number(imageCount) === 3) {
      if (image1Files.length === 0) {
        setErrorMessage('No.1 アピールの写真を1枚以上添付してください。');
      } else if (image1Title === '') {
        setErrorMessage('No.1 アピールのタイトルを入力してください。');
      } else if (image1Desc === '') {
        setErrorMessage('No.1 アピールの詳細を入力してください。');
      } else if (image2Files.length === 0) {
        setErrorMessage('No.2 アピールの写真を1枚以上添付してください。');
      } else if (image2Title === '') {
        setErrorMessage('No.2 アピールのタイトルを入力してください。');
      } else if (image2Desc === '') {
        setErrorMessage('No.2 アピールの詳細を入力してください。');
      } else if (image3Files.length === 0) {
        setErrorMessage('No.3 アピールの写真を1枚以上添付してください。');
      } else if (image3Title === '') {
        setErrorMessage('No.3 アピールのタイトルを入力してください。');
      } else if (image3Desc === '') {
        setErrorMessage('No.3 アピールの詳細を入力してください。');
      } else {
        save(true);
      }
    }
  }

  async function save(e) {


    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      status_image: e,

      recruit_imageCount: imageCount,

      // 店舗情報 > 情報
      recruit_image1Title: image1Title,
      recruit_image1Desc: image1Desc,
      recruit_image1FilesName: image1FilesName,
      
      recruit_image2Title: image2Title,
      recruit_image2Desc: image2Desc,
      recruit_image2FilesName: image2FilesName,

      recruit_image3Title: image3Title,
      recruit_image3Desc: image3Desc,
      recruit_image3FilesName: image3FilesName,

      last_time: new Date(),
    }).then(() => {
      history.push(`/edit/id=${company}+page=2+recruitId=${recruit}`);
    })

    for (let i = 0; i < image1FilePaths.length; i++){
      if (image1FilesName[i].length === 36) {
        firebase.storage().ref().child(`/recruitImage1/${image1FilesName[i]}`).put(image1FilePaths[i]);
      }
    }

    for (let i = 0; i < image1Before.length; i++){
      if (!image1FilePaths.includes(image1Before[i])) {
        firebase.storage().ref().child(`/recruitImage1/${image1Before[i]}`).delete();
        firebase.storage().ref().child(`/recruitImage1/resize_images/${image1Before[i]}_1080x1080`).delete();
        firebase.storage().ref().child(`/recruitImage1/resize_images/${image1Before[i]}_750x750`).delete();
        firebase.storage().ref().child(`/recruitImage1/resize_images/${image1Before[i]}_500x500`).delete();
        firebase.storage().ref().child(`/recruitImage1/resize_images/${image1Before[i]}_200x200`).delete();
      }
    }

    for (let i = 0; i < image2FilePaths.length; i++){
      if (image2FilesName[i].length === 36) {
        firebase.storage().ref().child(`/recruitImage2/${image2FilesName[i]}`).put(image2FilePaths[i]);
      }
    }

    for (let i = 0; i < image2Before.length; i++){
      if (!image2FilePaths.includes(image2Before[i])) {
        firebase.storage().ref().child(`/recruitImage2/${image2Before[i]}`).delete();
        firebase.storage().ref().child(`/recruitImage2/resize_images/${image2Before[i]}_1080x1080`).delete();
        firebase.storage().ref().child(`/recruitImage2/resize_images/${image2Before[i]}_750x750`).delete();
        firebase.storage().ref().child(`/recruitImage2/resize_images/${image2Before[i]}_500x500`).delete();
        firebase.storage().ref().child(`/recruitImage2/resize_images/${image2Before[i]}_200x200`).delete();
      }
    }

    for (let i = 0; i < image3FilePaths.length; i++){
      if (image3FilesName[i].length === 36) {
        firebase.storage().ref().child(`/recruitImage3/${image3FilesName[i]}`).put(image3FilePaths[i]);
      }
    }

    for (let i = 0; i < image3Before.length; i++){
      if (!image3FilePaths.includes(image3Before[i])) {
        firebase.storage().ref().child(`/recruitImage3/${image3Before[i]}`).delete();
        firebase.storage().ref().child(`/recruitImage3/resize_images/${image3Before[i]}_1080x1080`).delete();
        firebase.storage().ref().child(`/recruitImage3/resize_images/${image3Before[i]}_750x750`).delete();
        firebase.storage().ref().child(`/recruitImage3/resize_images/${image3Before[i]}_500x500`).delete();
        firebase.storage().ref().child(`/recruitImage3/resize_images/${image3Before[i]}_200x200`).delete();
      }
    }
  }

  const styleImages1 = e => {
    var styleImageTmp = [];
    var styleImagePathsTmp = [];
    var uid = uuid();

    if (e.target.files[0]) {
      styleImageTmp.push(URL.createObjectURL(e.target.files[0]));
      styleImagePathsTmp.push(e.target.files[0]);
      
      setImage1Files(image1Files.length === 0 ? styleImageTmp : [...image1Files, ...styleImageTmp]);
      setImage1FilePaths(image1FilePaths.length === 0 ? styleImagePathsTmp : [...image1FilePaths, ...styleImagePathsTmp]);
      setImage1FilesName([...image1FilesName, uid])
    }
  }

  const styleImages2 = e => {
    var styleImageTmp = [];
    var styleImagePathsTmp = [];
    var uid = uuid();

    if (e.target.files[0]) {
      styleImageTmp.push(URL.createObjectURL(e.target.files[0]));
      styleImagePathsTmp.push(e.target.files[0]);
      
      setImage2Files(image2Files.length === 0 ? styleImageTmp : [...image2Files, ...styleImageTmp]);
      setImage2FilePaths(image2FilePaths.length === 0 ? styleImagePathsTmp : [...image2FilePaths, ...styleImagePathsTmp]);
      setImage2FilesName([...image2FilesName, uid])
    }
  }

  const styleImages3 = e => {
    var styleImageTmp = [];
    var styleImagePathsTmp = [];
    var uid = uuid();

    if (e.target.files[0]) {
      styleImageTmp.push(URL.createObjectURL(e.target.files[0]));
      styleImagePathsTmp.push(e.target.files[0]);
      
      setImage3Files(image3Files.length === 0 ? styleImageTmp : [...image3Files, ...styleImageTmp]);
      setImage3FilePaths(image3FilePaths.length === 0 ? styleImagePathsTmp : [...image3FilePaths, ...styleImagePathsTmp]);
      setImage3FilesName([...image3FilesName, uid])
    }
  }
  
  
  return (
    <>
    <Header />

    <div className={Styles.store}>
      <div className={Styles.store_box}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        {/* 店舗理念 */}

        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>項目数</h5>

          <DefaultTitle title={'項目数'} required={true} />
          <select className={Styles.select}
            value={imageCount} onChange={(e) => setImageCount(Number(e.target.value))}>
            <option value={0}>未選択</option>
            <option value={1}>1つ</option>
            <option value={2}>2つ</option>
            <option value={3}>3つ</option>
          </select>
          <p className={Styles.desc}>　</p>

        </div>

        {1 <= Number(imageCount) && Number(imageCount) <= 3 ?
          <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.1 アピール</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>アピール写真（3枚まで）</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            {image1Files.length >= 3 ? <></> :
              <div class={Styles.store_input_btn}>
                <label>
                  写真添付
                  <input type="file" onChange={styleImages1} />
                </label>
              </div>
            }
          </div>

          <div className={Styles.image_prev}>
            {image1Files.length === 0 ?
              <p className={Styles.desc}>写真が添付されていません。</p>
              :
              <p className={Styles.desc}></p>
            }

            {image1Files.map((data, index) =>
              <div>
                <img src={data.length === 36 ? `https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage1%2Fresize_images%2F${data}_500x500?alt=media&token=?${time.getTime()}` : data} alt=''
                  onError={e => {
                    e.target.onError = null;
                    e.target.src = DefaultImage;
                  }}/>
                <ImCross className={Styles.image_prev_icon}
                  onClick={() => {
                    var v = [ ...image1Files ];
                    v.splice( index, 1 );
                    var array = v;
                    setImage1Files(array);
                    
                    var x = [ ...image1FilePaths ];
                    x.splice( index, 1 );
                    var arrays = x;
                    setImage1FilePaths(arrays);
                    
                    var y = [ ...image1FilesName ];
                    y.splice( index, 1 );
                    var arrayss = y;
                    setImage1FilesName(arrayss);
                  }}
                />
              </div>
            )}
          </div>

          <DefaultInput1
            title={'タイトル'} required={true} length={30} place={''} type={'text'}
            value={image1Title} setValue={setImage1Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={image1Desc} setValue={setImage1Desc}
            desc={''}
          />

        </div> : <></> }

        {2 <= Number(imageCount) && Number(imageCount) <= 3 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.2 アピール</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>アピール写真（3枚まで）</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            {image2Files.length >= 3 ? <></> :
              <div class={Styles.store_input_btn}>
                <label>
                  写真添付
                  <input type="file" onChange={styleImages2} />
                </label>
              </div>
            }
          </div>

          <div className={Styles.image_prev}>
            {image2Files.length === 0 ?
              <p className={Styles.desc}>写真が添付されていません。</p>
              :
              <p className={Styles.desc}></p>
            }

            {image2Files.map((data, index) =>
              <div>
                <img src={data.length === 36 ? `https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage2%2Fresize_images%2F${data}_500x500?alt=media&token=?${time.getTime()}` : data} alt=''
                  onError={e => {
                    e.target.onError = null;
                    e.target.src = DefaultImage;
                  }}/>
                <ImCross className={Styles.image_prev_icon}
                  onClick={() => {
                    var v = [ ...image2Files ];
                    v.splice( index, 1 );
                    var array = v;
                    setImage2Files(array);
                    
                    var x = [ ...image2FilePaths ];
                    x.splice( index, 1 );
                    var arrays = x;
                    setImage2FilePaths(arrays);
                    
                    var y = [ ...image2FilesName ];
                    y.splice( index, 1 );
                    var arrayss = y;
                    setImage2FilesName(arrayss);
                  }}
                />
              </div>
            )}
          </div>

          <DefaultInput1
            title={'タイトル'} required={true} length={30} place={''} type={'text'}
            value={image2Title} setValue={setImage2Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={image2Desc} setValue={setImage2Desc}
            desc={''}
          />

        </div> : <></> }

        {3 <= Number(imageCount) && Number(imageCount) <= 3 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.3 アピール</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>アピール写真（3枚まで）</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            {image3Files.length >= 3 ? <></> :
              <div class={Styles.store_input_btn}>
                <label>
                  写真添付
                  <input type="file" onChange={styleImages3} />
                </label>
              </div>
            }
          </div>

          <div className={Styles.image_prev}>
            {image3Files.length === 0 ?
              <p className={Styles.desc}>写真が添付されていません。</p>
              :
              <p className={Styles.desc}></p>
            }

            {image3Files.map((data, index) =>
              <div>
                <img src={data.length === 36 ? `https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage3%2Fresize_images%2F${data}_500x500?alt=media&token=?${time.getTime()}` : data} alt=''
                  onError={e => {
                    e.target.onError = null;
                    e.target.src = DefaultImage;
                  }}/>
                  
                <ImCross className={Styles.image_prev_icon}
                  onClick={() => {
                    var v = [ ...image3Files ];
                    v.splice( index, 1 );
                    var array = v;
                    setImage3Files(array);
                    
                    var x = [ ...image3FilePaths ];
                    x.splice( index, 1 );
                    var arrays = x;
                    setImage3FilePaths(arrays);
                    
                    var y = [ ...image3FilesName ];
                    y.splice( index, 1 );
                    var arrayss = y;
                    setImage3FilesName(arrayss);
                  }}
                />
              </div>
            )}
          </div>

          <DefaultInput1
            title={'タイトル'} required={true} length={30} place={''} type={'text'}
            value={image3Title} setValue={setImage3Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={image3Desc} setValue={setImage3Desc}
            desc={''}
          />

        </div> : <></> }

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>

      </div>
    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={recruit}
        setBackStatus={setBackStatus}
      />
    : <></> }

    </>
  );
}

export default EditStore;
