import Styles from './styles.module.css';

const Footer = (props) => {

  return (
    <>
    <div className={Styles.content}>
      <h4>募集内容</h4>
      <table className={Styles.table}>
        {/* <tr>
          <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>職種・役職</strong></th>
          <td><p>{props.recruitList.recruit_post}</p></td>
        </tr> */}
        <tr>
          <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>雇用形態</strong></th>
          <td><p>{props.recruitList.recruit_employmentStatus}</p></td>
        </tr>
        <tr>
          <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>給与</strong></th>
          <td><p>
            月給：{props.recruitList.recruit_monthlySalaryStart}万円<br />
            想定年収：{props.recruitList.recruit_annualIncomeStart}万円~{props.recruitList.recruit_annualIncomeEnd}万円<br />
          </p></td>
        </tr>
        {props.recruitList.recruit_overtime ? 
          <tr>
            <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>みなし残業代</strong></th>
            <td><p>
              {props.recruitList.recruit_overtime ? props.recruitList.recruit_overtimeOther : ''}
            </p></td>
          </tr>
        : <></> }
        {/* <tr>
          <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>給与形態</strong></th>
          <td>
            <p>
            {props.recruitList.recruit_salary === '年俸' ?
            <>年俸制：{props.recruitList.recruit_annualSalaryStart}万円~{props.recruitList.recruit_annualSalaryEnd}万円<br/></> 
            : props.recruitList.recruit_salary === '月給' ?
            <>
              月給：{props.recruitList.recruit_monthlySalaryStart}万円~{props.recruitList.recruit_monthlySalaryEnd}万円<br/>
              想定年収：{props.recruitList.recruit_annualIncomeStart}万円~{props.recruitList.recruit_annualIncomeEnd}万円<br/>
            </>
            : props.recruitList.recruit_salary === '日給' ?
            <>日給：{props.recruitList.recruit_dailyWageStart}万円~{props.recruitList.recruit_dailyWageEnd}万円<br/></> 
            : props.recruitList.recruit_salary === '時給' ?
            <>日給：{props.recruitList.recruit_hourWageStart}万円~{props.recruitList.recruit_hourWageEnd}万円<br/></>
            : props.recruitList.recruit_salary === '完全報酬(業務委託のみ)' ?
            <>完全報酬(業務委託のみ)：<br />{props.recruitList.recruit_successFee}<br/></>
            : <></> }
            {props.recruitList.recruit_overtime ?
              <>みなし残業：<br/>{props.recruitList.recruit_overtimeOther}<br/></>
            : <></> }
            </p>
          </td>
        </tr> */}
        {props.recruitList.recruit_welfare.length === 0 ? <></> :
          <tr>
            <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>福利厚生</strong></th>
            <td><p>
              {props.recruitList.recruit_welfare.map((data, index) => <>{data}、</> )}
              {props.recruitList.recruit_welfareCheck ? `${props.recruitList.recruit_welfareOther}` : ""}
            </p></td>
          </tr>
        }
        {props.recruitList.recruit_salaryWelfare === "" ? <></> :
          <tr>
            <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>給与体系・福利厚生に関する備考</strong></th>
            <td><p>
              {props.recruitList.recruit_salaryWelfare}
            </p></td>
          </tr>
        }
        {props.recruitList.recruit_holiday.length === 0 ? <></> :
          <tr>
            <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>休日・休暇</strong></th>
            <td><p>{props.recruitList.recruit_holiday.map((data, index) => <>{data}、</> )}
              {props.recruitList.recruit_holidayCheck ? `${props.recruitList.recruit_holidayOther}` : ""}</p></td>
          </tr>
        }
        <tr>
          <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>勤務時間</strong></th>
          <td><p>{props.recruitList.recruit_workingHoursStart}~{props.recruitList.recruit_workingHoursEnd}</p></td>
        </tr>
        {/* {1 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
        <tr>
          <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>①店舗<br />住所・アクセス</strong></th>
          <td>
            <p>
            {props.recruitList.place_store1Name}<br />
            {'<'} 住所 {'>'}<br />
            〒{props.recruitList.place_store1PostCode}<br />
            {props.recruitList.place_store1Address}<br />
            {'<'} 最寄り駅 {'>'}<br />
            {1 <= props.recruitList.place_store1Count && props.recruitList.place_store1Count <= 3 ?
              <>{props.recruitList.place_store1Station1Name.slice(0, props.recruitList.place_store1Station1Name.indexOf('（'))} {props.recruitList.place_store1Station1Time}、</> : <></> }
            {2 <= props.recruitList.place_store1Count && props.recruitList.place_store1Count <= 3 ?
              <>{props.recruitList.place_store1Station2Name.slice(0, props.recruitList.place_store1Station2Name.indexOf('（'))} {props.recruitList.place_store1Station2Time}、</> : <></> }
            {3 <= props.recruitList.place_store1Count && props.recruitList.place_store1Count <= 3 ?
              <>{props.recruitList.place_store1Station3Name.slice(0, props.recruitList.place_store1Station3Name.indexOf('（'))} {props.recruitList.place_store1Station3Time}</> : <></> }
            </p>
          </td>
        </tr>
        : <></> }
        {2 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
        <tr>
          <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>②店舗<br />住所・アクセス</strong></th>
          <td><p>
            {props.recruitList.place_store2Name}<br />
            {'<'} 住所 {'>'}<br />
            〒{props.recruitList.place_store2PostCode}<br />
            {props.recruitList.place_store2Address}<br />
            {'<'} 最寄り駅 {'>'}<br />
            {1 <= props.recruitList.place_store2Count && props.recruitList.place_store2Count <= 3 ?
              <>{props.recruitList.place_store2Station1Name.slice(0, props.recruitList.place_store2Station1Name.indexOf('（'))} {props.recruitList.place_store2Station1Time}、</> : <></> }
            {2 <= props.recruitList.place_store2Count && props.recruitList.place_store2Count <= 3 ?
              <>、{props.recruitList.place_store2Station2Name.slice(0, props.recruitList.place_store2Station2Name.indexOf('（'))} {props.recruitList.place_store2Station2Time}、</> : <></> }
            {3 <= props.recruitList.place_store2Count && props.recruitList.place_store2Count <= 3 ?
              <>、{props.recruitList.place_store2Station3Name.slice(0, props.recruitList.place_store2Station3Name.indexOf('（'))} {props.recruitList.place_store2Station3Time}</> : <></> }
            </p>
          </td>
        </tr>
        : <></> }
        {3 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
        <tr>
          <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>③店舗<br />住所・アクセス</strong></th>
          <td>
            <p>
            {props.recruitList.place_store3Name}<br />
            {'<'} 住所 {'>'}<br />
            〒{props.recruitList.place_store3PostCode}<br />
            {props.recruitList.place_store3Address}<br />
            {'<'} 最寄り駅 {'>'}<br />
            {1 <= props.recruitList.place_store3Count && props.recruitList.place_store3Count <= 3 ?
              <>{props.recruitList.place_store3Station1Name.slice(0, props.recruitList.place_store3Station1Name.indexOf('（'))} {props.recruitList.place_store3Station1Time}、</> : <></> }
            {2 <= props.recruitList.place_store3Count && props.recruitList.place_store3Count <= 3 ?
              <>{props.recruitList.place_store3Station2Name.slice(0, props.recruitList.place_store3Station2Name.indexOf('（'))} {props.recruitList.place_store3Station2Time}、</> : <></> }
            {3 <= props.recruitList.place_store3Count && props.recruitList.place_store3Count <= 3 ?
              <>{props.recruitList.place_store3Station3Name.slice(0, props.recruitList.place_store3Station3Name.indexOf('（'))} {props.recruitList.place_store3Station3Time}</> : <></> }
            </p>
          </td>
        </tr> : <></> }
        {4 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
        <tr>
          <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>④店舗<br />住所・アクセス</strong></th>
          <td>
            <p>
              {props.recruitList.place_store4Name}<br />
              {'<'} 住所 {'>'}<br />
              〒{props.recruitList.place_store4PostCode}<br />
              {props.recruitList.place_store4Address}<br />
              {'<'} 最寄り駅 {'>'}<br />
              {1 <= props.recruitList.place_store4Count && props.recruitList.place_store4Count <= 3 ?
                <>{props.recruitList.place_store4Station1Name.slice(0, props.recruitList.place_store4Station1Name.indexOf('（'))} {props.recruitList.place_store4Station1Time}、</> : <></> }
              {2 <= props.recruitList.place_store4Count && props.recruitList.place_store4Count <= 3 ?
                <>{props.recruitList.place_store4Station2Name.slice(0, props.recruitList.place_store4Station2Name.indexOf('（'))} {props.recruitList.place_store4Station2Time}、</> : <></> }
              {3 <= props.recruitList.place_store4Count && props.recruitList.place_store4Count <= 3 ?
                <>{props.recruitList.place_store4Station3Name.slice(0, props.recruitList.place_store4Station3Name.indexOf('（'))} {props.recruitList.place_store4Station3Time}</> : <></> }
            </p>
          </td>
        </tr> : <></> }
        {5 === props.recruitList.place_storeCount ?
        <tr>
          <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>⑤店舗<br />住所・アクセス</strong></th>
          <td><p>
            {props.recruitList.place_store5Name}<br />
            {'<'} 住所 {'>'}<br />
            〒{props.recruitList.place_store5PostCode}<br />
            {props.recruitList.place_store5Address}<br />
            {'<'} 最寄り駅 {'>'}<br />
            {1 <= props.recruitList.place_store5Count && props.recruitList.place_store5Count <= 3 ?
              <>{props.recruitList.place_store5Station1Name.slice(0, props.recruitList.place_store5Station1Name.indexOf('（'))} {props.recruitList.place_store5Station1Time}</> : <></> }
            {2 <= props.recruitList.place_store5Count && props.recruitList.place_store5Count <= 3 ?
              <>{props.recruitList.place_store5Station2Name.slice(0, props.recruitList.place_store5Station1Name.indexOf('（'))} {props.recruitList.place_store5Station2Time}</> : <></> }
            {3 <= props.recruitList.place_store5Count && props.recruitList.place_store5Count <= 3 ?
              <>{props.recruitList.place_store5Station3Name.slice(0, props.recruitList.place_store5Station1Name.indexOf('（'))} {props.recruitList.place_store5Station3Time}</> : <></> }</p>
          </td>
        </tr> : <></> } */}
        {/* {props.recruitList.store_homepage === '' ? <></> :
          <tr>
            <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>ホームページ</strong></th>
            <td>
              <a href={props.recruitList.store_homepage} target="_blank" rel="noopener noreferrer">
              <p>
                {props.recruitList.store_homepage}
              </p>
              </a>
            </td>
          </tr>
        } */}
        {/* {props.recruitList.document_document ?
          <tr>
            <th style={{width : '150px', backgroundColor : '#FFEFF1'}}><strong>提出書類</strong></th>
            <td>
              <p>
              {props.recruitList.document_resume ? '履歴書' : <></> }
              {props.recruitList.document_coordinate ? '、コーディネート、' : <></> }
              {props.recruitList.document_otherDocument ? `
                ${1 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
                  `${props.recruitList.document_otherDocument01}` : '' }
                ${2 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
                  `、${props.recruitList.document_otherDocument02}` : '' }
                ${3 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
                  `、${props.recruitList.document_otherDocument02}` : '' }` : <></> }
              </p>
            </td>
          </tr>
        : <></> } */}
      </table>
    </div>
    </>
  );
}

export default Footer;
