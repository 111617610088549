import React, { useState, useLayoutEffect } from 'react';
import Styles from './styles.module.css';

const Footer = (props) => {

  var link = new URL(window.location).href;
  var recruit = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  // 左
  const [ triangleData1, setTriangleData1 ] = useState('');
  const [ squareData1, setSquareData1 ] = useState('');
  const [ dotTopData1, setDotTopData1 ] = useState('');
  const [ dotLeftData1, setDotLeftData1 ] = useState('');

  const [ triangleData2, setTriangleData2 ] = useState('');
  const [ squareData2, setSquareData2 ] = useState('');
  const [ dotTopData2, setDotTopData2 ] = useState('');
  const [ dotLeftData2, setDotLeftData2 ] = useState('');

  const [ triangleData3, setTriangleData3 ] = useState('');
  const [ squareData3, setSquareData3 ] = useState('');
  const [ dotTopData3, setDotTopData3 ] = useState('');
  const [ dotLeftData3, setDotLeftData3 ] = useState('');

  const [ triangleData4, setTriangleData4 ] = useState('');
  const [ squareData4, setSquareData4 ] = useState('');
  const [ dotTopData4, setDotTopData4 ] = useState('');
  const [ dotLeftData4, setDotLeftData4 ] = useState('');

  const [ triangleData5, setTriangleData5 ] = useState('');
  const [ squareData5, setSquareData5 ] = useState('');
  const [ dotTopData5, setDotTopData5 ] = useState('');
  const [ dotLeftData5, setDotLeftData5 ] = useState('');

  const [ triangleData6, setTriangleData6 ] = useState('');
  const [ squareData6, setSquareData6 ] = useState('');
  const [ dotTopData6, setDotTopData6 ] = useState('');
  const [ dotLeftData6, setDotLeftData6 ] = useState('');

  useLayoutEffect(() => {

    PentagonTreatment(props.pentagonTreatment[0], props.pentagonTreatment[1], props.pentagonTreatment[2], props.pentagonTreatment[3], props.pentagonTreatment[4], props.pentagonTreatment[5],);

  }, [recruit]);

  // 69.0983005625, 75.27864045, 81.4589803375, 87.639320225, 93.8196601125
  // 69.4073175569, 75.5876574444, 81.7679973319, 87.9483372194, 94.1286771069

  // 100
  // 底辺：95.105651629515
  // 高さ：30.901699437495 = 69.0983005625
  // 99
  // 底辺：94.15459511322
  // 高さ：30.59268244312 = 69.4073175569

  // 80
  // 底辺：76.084521303612
  // 高さ：24.721359549996 = 75.27864045
  // 79
  // 底辺：75.133464787317
  // 高さ：24.412342555621 = 75.5876574444

  // 60
  // 底辺：57.063390977709
  // 高さ：18.541019662497 = 81.4589803375
  // 59
  // 底辺：56.112334461414
  // 高さ：18.232002668122 = 81.7679973319

  // 40
  // 底辺：38.042260651806
  // 高さ：12.360679774998 = 87.639320225
  // 39
  // 底辺：37.091204135511
  // 高さ：12.051662780623 = 87.9483372194

  // 20
  // 底辺：19.021130325903
  // 高さ：6.1803398874989 = 93.8196601125
  // 19
  // 底辺：18.070073809608
  // 高さ：5.871322893124 = 94.1286771069


  // var position100bottom = 95.105651629515;
  // var position100height = 30.901699437495;

  // var position99bottom = 94.15459511322;
  // var position99height = 30.59268244312;

  // var position80bottom = 76.084521303612;
  // var position80height = 24.721359549996;

  // var position79bottom = 75.133464787317;
  // var position79height = 24.412342555621;

  // var position60bottom = 57.063390977709;
  // var position60height = 18.541019662497;

  // var position59bottom = 56.112334461414;
  // var position59height = 18.232002668122;

  // var position40bottom = 38.042260651806;
  // var position40height = 12.360679774998;

  // var position39bottom = 37.091204135511;
  // var position39height = 12.051662780623;

  // var position20bottom = 19.021130325903;
  // var position20height = 6.1803398874989;

  // var position19bottom = 18.070073809608;
  // var position19height = 5.871322893124;
  
  
  function PentagonTreatment(number1, number2, number3, number4, number5, number6) {

    var positionbottom1 = [ 18.186533479473, 35.507041555162, 52.827549630851, 70.14805770654, 87.468565782228,  ];
    var positionheight1 = [ 89.5, 79.5, 69.5, 59.5, 49.5, ];

    var positionbottom2 = [ 17.28, 34.56, 51.84, 69.12, 86.4,  ];
    var positionheight2 = [ 90, 80, 70, 60, 50, ];

    var triangleString1 = `polygon(0 ${100 - number1 * 20}%, 0 100%, ${positionbottom2[number2 - 1]}% ${positionheight2[number2 - 1]}%)`;
    setTriangleData1(triangleString1);
    var squareString1 = `polygon(0 ${100 - number1 * 20}%, 0 ${101 - number1 * 20}%,  ${positionbottom2[number2 - 1]}% ${positionheight2[number2 - 1]}%, ${positionbottom1[number2 - 1]}% ${positionheight1[number2 - 1]}%)`;
    setSquareData1(squareString1);
    var dotTopString1 = `calc(${positionheight1[number2 - 1]}% - 4px)`;
    setDotTopData1(dotTopString1);
    var dotLeftString1 = `calc(${positionbottom1[number2 - 1]}% - 4px)`;
    setDotLeftData1(dotLeftString1);

    var string2 = `polygon(0 ${100 - number2 * 20}%, 0 100%, ${positionbottom2[number3 - 1]}% ${positionheight2[number3 - 1]}%)`;
    setTriangleData2(string2);
    var squareString2 = `polygon(0 ${100 - number2 * 20}%, 0 ${101 - number2 * 20}%, ${positionbottom2[number3 - 1]}% ${positionheight2[number3 - 1]}%, ${positionbottom1[number3 - 1]}% ${positionheight1[number3 - 1]}%)`;
    setSquareData2(squareString2);
    var dotTopString2 = `calc(${positionheight1[number3 - 1]}% - 4px)`;
    setDotTopData2(dotTopString2);
    var dotLeftString2 = `calc(${positionbottom1[number3 - 1]}% - 4px)`;
    setDotLeftData2(dotLeftString2);

    var string3 = `polygon(0 ${100 - number3 * 20}%, 0 100%, ${positionbottom2[number4 - 1]}% ${positionheight2[number4 - 1]}%)`;
    setTriangleData3(string3);
    var squareString3 = `polygon(0 ${100 - number3 * 20}%, 0 ${101 - number3 * 20}%,  ${positionbottom2[number4 - 1]}% ${positionheight2[number4 - 1]}%, ${positionbottom1[number4 - 1]}% ${positionheight1[number4 - 1]}%)`;
    setSquareData3(squareString3);
    var dotTopString3 = `calc(${positionheight1[number4 - 1]}% - 4px)`;
    setDotTopData3(dotTopString3);
    var dotLeftString3 = `calc(${positionbottom1[number4 - 1]}% - 4px)`;
    setDotLeftData3(dotLeftString3);

    var string4 = `polygon(0 ${100 - number4 * 20}%, 0 100%, ${positionbottom2[number5 - 1]}% ${positionheight2[number5 - 1]}%)`;
    setTriangleData4(string4);
    var squareString4 = `polygon(0 ${100 - number4 * 20}%, 0 ${101 - number4 * 20}%, ${positionbottom2[number5 - 1]}% ${positionheight2[number5 - 1]}%, ${positionbottom1[number5 - 1]}% ${positionheight1[number5 - 1]}%)`;
    setSquareData4(squareString4);
    var dotTopString4 = `calc(${positionheight1[number5 - 1]}% - 4px)`;
    setDotTopData4(dotTopString4);
    var dotLeftString4 = `calc(${positionbottom1[number5 - 1]}% - 4px)`;
    setDotLeftData4(dotLeftString4);

    var string5 = `polygon(0 ${100 - number5 * 20}%, 0 100%, ${positionbottom2[number6 - 1]}% ${positionheight2[number6 - 1]}%)`;
    setTriangleData5(string5);
    var squareString5 = `polygon( 0 ${100 - number5 * 20}%, 0 ${101 - number5 * 20}%, ${positionbottom2[number6 - 1]}% ${positionheight2[number6 - 1]}%, ${positionbottom1[number6 - 1]}% ${positionheight1[number6 - 1]}%)`;
    setSquareData5(squareString5);
    var dotTopString5 = `calc(${positionheight1[number6 - 1]}% - 4px)`;
    setDotTopData5(dotTopString5);
    var dotLeftString5 = `calc(${positionbottom1[number6 - 1]}% - 4px)`;
    setDotLeftData5(dotLeftString5);

    var string6 = `polygon(0 ${100 - number6 * 20}%, 0 100%, ${positionbottom2[number1 - 1]}% ${positionheight2[number1 - 1]}%)`;
    setTriangleData6(string6);
    var squareString6 = `polygon(0 ${100 - number6 * 20}%, 0 ${101 - number6 * 20}%, ${positionbottom2[number1 - 1]}% ${positionheight2[number1 - 1]}%, ${positionbottom1[number1 - 1]}% ${positionheight1[number1 - 1]}%)`;
    setSquareData6(squareString6);
    var dotTopString6 = `calc(${positionheight1[number1 - 1]}% - 4px)`;
    setDotTopData6(dotTopString6);
    var dotLeftString6 = `calc(${positionbottom1[number1 - 1]}% - 4px)`;
    setDotLeftData6(dotLeftString6);
  }


  return (
    <>
    <div className={Styles.text_pentagon}>
      <div className={Styles.pentagon_back}>
        <div className={Styles.pentagon}>
          <div className={Styles.triangle}>

            <div className={Styles.triangle1} style={{clipPath : triangleData1}}></div>
            <div className={Styles.triangleBorder1} style={{clipPath : squareData1}}></div>
            <div className={Styles.triangleDot1}>
              <div style={{marginTop : dotTopData1, marginLeft : dotLeftData1}}></div>
            </div>

            <div className={Styles.triangle2} style={{clipPath : triangleData2}}></div>
            <div className={Styles.triangleBorder2} style={{clipPath : squareData2}}></div>
            <div className={Styles.triangleDot2}>
              <div style={{marginTop : dotTopData2, marginLeft : dotLeftData2}}></div>
            </div>

            <div className={Styles.triangle3} style={{clipPath : triangleData3}}></div>
            <div className={Styles.triangleBorder3} style={{clipPath : squareData3}}></div>
            <div className={Styles.triangleDot3}>
              <div style={{marginTop : dotTopData3, marginLeft : dotLeftData3}}></div>
            </div>

            <div className={Styles.triangle4} style={{clipPath : triangleData4}}></div>
            <div className={Styles.triangleBorder4} style={{clipPath : squareData4}}></div>
            <div className={Styles.triangleDot4}>
              <div style={{marginTop : dotTopData4, marginLeft : dotLeftData4}}></div>
            </div>

            <div className={Styles.triangle5} style={{clipPath : triangleData5}}></div>
            <div className={Styles.triangleBorder5} style={{clipPath : squareData5}}></div>
            <div className={Styles.triangleDot5}>
              <div style={{marginTop : dotTopData5, marginLeft : dotLeftData5}}></div>
            </div>

            <div className={Styles.triangle6} style={{clipPath : triangleData6}}></div>
            <div className={Styles.triangleBorder6} style={{clipPath : squareData6}}></div>
            <div className={Styles.triangleDot6}>
              <div style={{marginTop : dotTopData6, marginLeft : dotLeftData6}}></div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Footer;
