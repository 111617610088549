import Styles from './styles.module.css';
import React, { useState } from 'react';
import DefaultTitle from '../../../package/parts/title';
import firebase from 'firebase/compat/app';
import DefaultImage from './../../../../../image/works/default/home.png';
import { FaPen } from "react-icons/fa";
import { v4 as uuid } from 'uuid';

const WorksAccountEdit = (props) => {

  const [ name, setName ] = useState('');
  
  const [ errorPhoto, setErrorPhoto ] = useState(false);
  const [ errorName, setErrorName ] = useState(false);
  
  const [ styleImageFile, setStyleImageFile ] = useState('');
  const [ styleImageFilePath, setStyleImageFilePath ] = useState('');

  const styleImages = e => {
    if (e.target.files[0]) {
      setStyleImageFile(URL.createObjectURL(e.target.files[0]));
      setStyleImageFilePath(e.target.files[0]);
    }
  }

  function save() {

    setErrorPhoto(false);
    setErrorName(false);
    
    if (styleImageFile === '') {
      setErrorPhoto(true);
    } else if (name === '') {
      setErrorName(true);
    } else {
      var docId = uuid();
      props.setMakeStore(false);

      firebase.firestore().collection('stores').doc(docId)
      .set({
        companyId: props.companyId,
        recruitId: '',
  
        number: props.length + 1,
        
        image: docId,
        name: name,
        
        status: false,
  
        postCode: '',
        address: '',
        count: 0,
        station1Name: '',
        station1Time: '',
        station2Name: '',
        station2Time: '',
        station3Name: '',
        station3Time: '',
  
        status_place: false,
  
        pentagonStyle1: 1,
        pentagonStyle2: 1,
        pentagonStyle3: 1,
        pentagonStyle4: 1,
        pentagonStyle5: 1,
        pentagonStyle6: 1,
  
        pentagonStyleInput: 1,
        pentagonTreatment1: 1,
        pentagonTreatment2: 1,
        pentagonTreatment3: 1,
        pentagonTreatment4: 1,
        pentagonTreatment5: 1,
        pentagonTreatment6: 1,
        
        status_pentagon: false,
  
        region: false,
        strengths: false,
        treatment: false,
        
        type: '',
  
        status_type: false,
  
        time: new Date(),
        last_time: new Date(),
      });
  
      firebase.storage().ref().child(`/stores/${docId}`).put(styleImageFilePath);
    }

  }

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => props.setMakeStore(false)}></div>
        <div className={Styles.box_contents}>

          <div className={Styles.title}>
            <h4>店舗追加</h4>
            <button onClick={() => save()}>追加</button>
          </div>

          <DefaultTitle title={'店舗写真'} required={true} />

          <div className={Styles.make_image}>
            <FaPen className={Styles.make_image_pen} />
            <input type="file" onChange={styleImages} />
            {styleImageFile === '' ?
              <img className={Styles.make_image_file} alt=''
                src={DefaultImage} 
                onError={e => {
                  e.target.onError = null;
                  e.target.src = DefaultImage;
                }}/>
            : 
              <img className={Styles.make_image_file} src={styleImageFile} alt='' />
            }
          </div>

          <p className={Styles.desc} style={errorPhoto ? { color : '#AD0200', fontWeight : 'bold' } : {}}>※店舗写真を追加してください。</p>

          <div className={Styles.title}>
            <div>
              <p>店舗名</p>
              <div className={Styles.title_yes}>※必須</div>
            </div>
            <p>{name.length} / 20</p>
          </div>
          <input className={Styles.input} placeholder={'メモリー 横浜店'} maxLength={20} type='text' 
            value={name} onChange={(e) => setName(e.target.value)} />
          <p className={Styles.desc} style={errorName ? { color : '#AD0200', fontWeight : 'bold' } : {}}>※店舗名を入力してください。</p>

        </div>
      </div>
    </div>
    </>
  );
}

export default WorksAccountEdit;
