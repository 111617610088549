import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid';
import firebase from 'firebase/compat/app'
import { useHistory } from 'react-router-dom';
import liff from "@line/liff";

const Footer = () => {

  var list = [
    "ginger_recruit",
    "zaza_official_salon",
    "vie.arashi",
    "mifuku.lanature",
    "paragon_maya",
    "jackbeansrecruit",
    "riddlehair_ishii",
    "modeks_nishinomiya_recruit",
    "organic.hairmake",
    "see.0302",
    "infinity_biyoushitsu",
    "moriwaki_25saiyou",
    "beautrium_265",
    "hagio_tigre",
    "mission_shin0310",
    "amixhairworks_recruit",
    "quartett.recruit",
    "quartett.recruit",
    "quartett.recruit",
    "modeks.ssb",
    "total_beauty.lolo.sub",
    "modeks.luz",
    "modeks_briller___",
    "modeks___tesoro",
    "modeksokinawa",
    "eau_modeks",
    "modeks___takarazuka",
    "ahu_itami",
    "base.2.5",
    "_radshinsaibashi_",
    "modeks_nishikita",
    "mode_ks.toyonaka",
    "modeks___tokyo___",
    "modeksrokkou",
    "modeks_laviebelle",
    "blanc_modeks",
    "modeks__ikeda",
    "modeksverde37",
    "laffin.matsubara",
    "modeks.ebina",
    "modeks_ibarakiten",
    "modeks_ikeda___",
    "mode_sapporo",
    "modeks_abeno",
    "mode_ks_amagasaki",
    "modeksrokkou",
    "_radshinsaibashi_",
    "mode_sapporo",
    "total_beauty.lolo.sub",
    "modeks__ikeda",
    "modeks_nishikita",
    "modeks___tokyo___",
    "modeks_briller___",
    "modeks_laviebelle",
    "modeks___takarazuka",
    "mode_ks_amagasaki",
    "modeks_tsukaguchi___",
    "modeksverde37",
    "improve0201",
    "ahu_itami",
    "modeks.ebina",
    "modeks_ikeda___",
    "modeks_ibarakiten",
    "modeksokinawa",
    "ishibashi2023314",
    "laffin.matsubara",
    "modeks.luz",
    "blanc_modeks",
    "modeks_abeno",
    "scene__by__rad",
    "see.0302",
    "modeks.matsubara",
    "hers_modeks",
    "eau_modeks",
    "modeks_atsugi",
    "mode_ks.toyonaka",
    "_ellesalon_",
    "radnoel2023",
    "base.2.5",
    "modeks___tesoro",
    "modeks.ssb",
  ]

  function addCompany() {
    var docId = uuid();

    // firebase.firestore().collection('companies').doc(docId)
    // .set({
    //   member_id: 'ars',
    //   id: docId,
    //   name: 'ars',
    //   logo : false,
    //   owner: [ 'V4N4thdGWTW8tYsZ31g31BBkjZr2' ],
    //   administrator: [ 'V4N4thdGWTW8tYsZ31g31BBkjZr2' ],
    //   member: [ 'V4N4thdGWTW8tYsZ31g31BBkjZr2' ],
    //   invite: [ 'ars.hachioji@gmail.com' ],
    //   // hair.studio.one044@gmail.com
    //   recruits: [],
    //   stores: [],
    //   time: new Date(),
    //   recruitLimit: 0,
    // })
    // // info@buzontokyo.con
    // .then(() => {
    //   // history.push(`/service/id=${docId}+page=1`);
    //   console.log('done');
    // })

    // firebase.firestore().collection('users').get()
    // .then(snapShot => {
    //   snapShot.forEach((doc) => {
    //     if (doc.data().user_character_score === undefined || doc.data().user_character_score.length === 0) {
    //       firebase.firestore().collection('users').doc(doc.id)
    //       .update({
    //         user_character_score: [ 1, 1, 1 ],
    //       });
    //     }
    //   });
    // });

    // firebase.firestore().collection('companies').orderBy("time", 'desc').get()
    // .then(snapShot => {
    //   snapShot.forEach((doc) => {
    //     console.log(doc.data().name);
    //   });
    // });

    const arrayB = list.filter((element, index) => {
      return list.indexOf(element) == index;
    }) 
    console.log(arrayB);

  }

  return (
    <>
    <button onClick={() => addCompany()}>会社追加</button>
    </>
  );
}

export default Footer;
