import React, { useState } from 'react';
import DefaultImg from './../../../../../image/works/default/recruit.png'
import Styles from './styles.module.css';

const Footer = (props) => {

  const [ storeImages, setStoreImages ] = useState(props.image);

  function apply() {
    // if (user) {
    //   history.push(`/applicants/id=${companyId}+page=1+recruitId=${recruit}`)
    // } else {
    // }
  }

  return (
    <>
    <div className={Styles.header_title}>
      <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${props.recruitList.companyId}_200x200?alt=media&token=?${new Date().getTime()}`} alt=''
        onError={e => {
          e.target.onError = null;
          e.target.src = DefaultImg;
        }}/>
      <h2>{props.recruitList.store_name}</h2>
    </div>
    
    <div className={Styles.header}>


      <h1>{props.recruitList.store_title}</h1>

      <div className={Styles.header_image}>

        {storeImages.length >= 1 ?
          <img alt='' 
            src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${storeImages[0]}_1080x1080?alt=media&token=?${new Date().getTime()}`}
            onError={e => {
              e.target.onError = null;
              e.target.src = DefaultImg;
            }} />
        : <img src={DefaultImg} alt='' /> }

        <div>

          {storeImages.length >= 2 ?
            <img alt='' onClick={() => setStoreImages([ storeImages[1], storeImages[0], storeImages[2], storeImages[3], storeImages[4], ])}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${storeImages[1]}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImg;
              }} />
          : <img src={DefaultImg} alt='' /> }

          {storeImages.length >= 3 ?
            <img alt='' onClick={() => setStoreImages([ storeImages[2], storeImages[1], storeImages[0], storeImages[3], storeImages[4], ])}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${storeImages[2]}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImg;
              }} />
          : <img src={DefaultImg} alt='' /> }

          {storeImages.length >= 4 ?
            <img alt='' onClick={() => setStoreImages([ storeImages[3], storeImages[1], storeImages[2], storeImages[0], storeImages[4], ])}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${storeImages[3]}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImg;
              }} />
          : <img src={DefaultImg} alt='' /> }

          {storeImages.length >= 5 ?
            <img alt='' onClick={() => setStoreImages([ storeImages[4], storeImages[1], storeImages[2], storeImages[3], storeImages[0], ])}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${storeImages[4]}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImg;
              }} />
          : <img src={DefaultImg} alt='' /> }

        </div>

      </div>

      <div className={Styles.hashTags}>{props.recruitList.store_hashTagList.map((data, index) => <p>#{data} </p> )}</div>

    </div>
    </>
  );
}

export default Footer;

