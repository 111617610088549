import Styles from './styles.module.css';
import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import DefaultInput1 from './../../package/parts/1-input';

const WorksAccountEdit = (props) => {

  const [ mailAddress, setMailAddress ] = useState(props.mailAddress);

  function check() {
    if (mailAddress !== '') {
      invite();
    }
  }

  function invite() {
    firebase.firestore().collection('companies').doc(props.companyList.id)
    .update({
      notification_mails: mailAddress,
    })
    .then(() => {
      props.setMail(false);
    });
  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => props.setMail(false)}></div>
        <div className={Styles.modal_contents}>

          <div className={Styles.modal_contents_title}>
            <h4>通知先アドレス</h4>
            <button onClick={() => check()}>招待</button>
          </div>

          <DefaultInput1
            title={'メールアドレス'} required={true} length={40} place={'memorii-works@trankllc.com'} type={'text'}
            value={mailAddress} setValue={setMailAddress}
            desc={'※メールアドレスを入力してください。'}
          />

        </div>
      </div>
    </div>
    </>
  );
}

export default WorksAccountEdit;
