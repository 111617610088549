// export PATH="$HOME/.npm-global/bin:$PATH"
// import "./styles/style.scss"
// gsutil cors set cors.json gs://trankllc-com.appspot.com/
// https://console.firebase.google.com/u/0/project/photo-beauty-24f63/firestore/data/~2F?hl=ja

// git remote add origin https://github.com/trank-ajay/works-web.git

import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';

import Top from './component/0.pages/top/index';
import Contact from './component/0.pages/contact/index';
import Terms from './component/0.pages/document/terms/index';
import Policy from './component/0.pages/document/policy/index';
import Company from './component/0.pages/landing/index';

import worksResults from './component/student/web/results/index';
import worksRecruit from './component/student/web/recruit/index';
import Student from './component/student/web/portal/index';

import studentSignIn from './component/auth/student/signin/index';
import studentLogin from './component/auth/student/login/1-login';
import studentReset from './component/auth/student/login/3-reset';
import studentConfirm from './component/auth/student/login/2-confirm';

import companySignIn from './component/auth/company/signin/index';
import companyLogin from './component/auth/company/login/1-login';
import companyReset from './component/auth/company/login/3-reset';
import companyConfirm from './component/auth/company/login/2-confirm';

import worksMakeAccount from './component/select/makeAccount/index';

import worksSelect from './component/select/index';

import worksService from './component/service/index';
import worksServiceStore from './component/service/2.recruit/7.store/index';
import worksCallback from './component/service/8.callback/index';
import worksEdit from './component/service/2.recruit/2.edit/index';

import worksEditStore from './component/service/2.recruit/2.edit/1.store/1.summary/index';
import worksEditArea from './component/service/2.recruit/7.store/1.edit/1.place/index';

import worksEditStoreSelect from './component/service/2.recruit/2.edit/1.store/2.place/index';

import worksEditPentagon from './component/service/2.recruit/7.store/1.edit/2.pentagon/index';
import worksEditType from './component/service/2.recruit/7.store/1.edit/3.type/index';

import worksEditPoint from './component/service/2.recruit/2.edit/2.recruit/1.point/index';
import worksEditNumber from './component/service/2.recruit/2.edit/2.recruit/2.number/index';
import worksEditPhoto from './component/service/2.recruit/2.edit/2.recruit/3.photo/index';
import worksEditInterview from './component/service/2.recruit/2.edit/2.recruit/4.interview/index';
import worksEditPerson from './component/service/2.recruit/2.edit/2.recruit/5.person/index';
import worksSiteRecruit from './component/service/2.recruit/2.edit/2.recruit/7.recruit/index';
import worksEditHashTag from './component/service/2.recruit/2.edit/2.recruit/6.hashTag/index';

import worksSiteTemplate from './component/service/2.recruit/2.edit/3.document/index';

import worksSiteEvaluation from './component/service/2.recruit/3.examination/index';

import worksApplicants from './component/student/applicants/index';
import worksDone from './component/student/applicants/done/index';

import add from './component/z_add/index';

import test from './component/test/index';

function App() {
  return (
    <>
    <BrowserRouter>

      <Route exact path="/" component={Top} />
      <Route exact path="/contact" component={Contact} />

      <Route exact path="/terms" component={Terms} />
      <Route exact path="/policy" component={Policy} />
      
      <Route exact path="/student" component={Student} />

      <Route exact path="/student/login" component={studentLogin} />
      <Route exact path="/student/signin" component={studentSignIn} />
      <Route exact path="/student/reset" component={studentReset} />
      <Route exact path="/student/confirm" component={studentConfirm} />
      <Route exact path="/student/make" component={worksMakeAccount} />

      <Route exact path="/company" component={Company} />

      <Route exact path="/company/login" component={companyLogin} />
      <Route exact path="/company/signin" component={companySignIn} />
      <Route exact path="/company/reset" component={companyReset} />
      <Route exact path="/company/confirm" component={companyConfirm} />
      <Route exact path="/company/make" component={worksMakeAccount} />

      <Route exact path="/results/:search" component={worksResults} />
      <Route exact path="/recruit/:id" component={worksRecruit} />

      {/* <Route exact path="/select/" component={worksSelect} /> */}

      <Route exact path="/service/:id" component={worksService} />
      <Route exact path="/edit/storeSetting/:id" component={worksServiceStore} />
      <Route exact path="/service/callback" component={worksCallback} />

      <Route exact path="/edit/:id" component={worksEdit} />

      <Route exact path="/edit/store/:id" component={worksEditStore} />

      <Route exact path="/edit/area/:id" component={worksEditArea} />
      <Route exact path="/edit/point/:id" component={worksEditPoint} />
      <Route exact path="/edit/pentagon/:id" component={worksEditPentagon} />

      <Route exact path="/edit/storeSelect/:id" component={worksEditStoreSelect} />
      <Route exact path="/edit/number/:id" component={worksEditNumber} />
      <Route exact path="/edit/photo/:id" component={worksEditPhoto} />
      <Route exact path="/edit/interview/:id" component={worksEditInterview} />
      <Route exact path="/edit/person/:id" component={worksEditPerson} />
      <Route exact path="/edit/recruit/:id" component={worksSiteRecruit} />
      <Route exact path="/edit/hashtag/:id" component={worksEditHashTag} />
      <Route exact path="/edit/type/:id" component={worksEditType} />

      <Route exact path="/edit/template/:id" component={worksSiteTemplate} />

      <Route exact path="/edit/evaluation/:id" component={worksSiteEvaluation} />

      <Route exact path="/applicants/:id" component={worksApplicants} />
      <Route exact path="/done/:id" component={worksDone} />

      <Route exact path="/add" component={add} />

      <Route exact path="/test" component={test} />

    </BrowserRouter>
    </>
  );
}

export default App;


// // "start": "react-scripts start functions-framework --target=updateHashTags",