import DocumentMeta from 'react-document-meta';
import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import Header from './header';
import firebase from 'firebase/compat/app';
import DefaultImage from './../../../../image/works/default/recruit.png'
import { useHistory } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Footer from './../footer/index';
import GoogleImage from './../../../../image/works/potal/header/app.png';
import AppImage from './../../../../image/works/potal/header/google.png';
import Registration from './../registration/index';

const WebPortal = () => {

  const meta = {
    title: '美容業界の新卒・アシスタント求人検索サイト（美容学生向け）| memorii works',
    description: '美容室へ就職される美容学生へ向けて行きたい美容室が見つかる就活サイト、美容学生を採用したい方へ向けて新卒採用・管理が行えるサービス',
    canonical: 'https://memorii-works.com',
    meta: { charset: 'utf-8', }
  };

  const history = useHistory();  

  const [ userList, setUserList ] = useState([]);
  const [ recruitList, setRecruitList ] = useState([]);

  const [ pref, setPref ] = useState('');
  const [ post, setPost ] = useState('');

  const [ modal, setModal ] = useState(false);

  useEffect(() => {
    var recruits = [];

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection('users').doc(user.uid).get()
        .then((doc) => {
          if (doc.exists) {
            setUserList(doc.data());
          } else {
            firebase.firestore().collection('users').doc(user.uid)
            .set({
              user_name: '',
              user_like_count : 0,
              user_award : 0,
              user_likes : [],
              user_text : '',
              user_uid : user.uid,
              user_createdAt : new Date(),
              user_scout : false,
              user_address : [],
              user_address_postalCode: [],
              user_badge: 0,
              user_business: "美容師",
              user_recruit: false,
              user_token: "",
              user_connection : [],
              user_connection_count : 0,
              user_connection_permission : [],
              user_connection_request : [],
              user_likes_list : [],
              user_login : '',
              user_login_count : [],
              user_notification : [],
              user_notification_confiram : false,
              user_post_like_count : 0,
              user_post_view_count : 0,
              user_views : '',
              user_post_count : 0,
              user_recruit_save: [],
              user_instagram : '',
              user_tiktok : '',
              memorii_score : 0,
            })
          }
        });
      }

      firebase.firestore().collection('recruits').orderBy('time', 'asc').get()
      .then(snapShot => {
        snapShot.forEach((doc) => {
          if (doc.data().status === 1) {
            recruits.push({...doc.data(), id: doc.id });
          }
        });
        setRecruitList(shuffleArray(recruits));
      });
    })

  }, []);

  const shuffleArray = (array) => {
    const cloneArray = [...array]

    for (let i = cloneArray.length - 1; i >= 0; i--) {
      let rand = Math.floor(Math.random() * (i + 1))
      // 配列の要素の順番を入れ替える
      let tmpStorage = cloneArray[i]
      cloneArray[i] = cloneArray[rand]
      cloneArray[rand] = tmpStorage
    }

    return cloneArray
  }

  function search() {
    history.push(`/results/search=${pref},+salary=+post=${post}+style=+page=0`);
    window.location.reload();
  }

  return (
    <>
    <DocumentMeta {...meta}>
      <Header />
      <div className={Styles.header}>
        <div className={Styles.title}>
          <h5>memorii works</h5>
          <h4>あなたに合った美容室を提案する</h4>
          <div>
            <img src={AppImage} alt=''  />
            <img src={GoogleImage} alt='' />
          </div>
        </div>
      </div>
      {/* <div className={Styles.search}>
        <h4>簡単検索</h4>
        <select onChange={(e) => setPref(e.target.value)} value={pref}>
          <option value=''>都道府県</option>
          <optgroup label='北海道'>
            <option value='北海道'>北海道</option>
          </optgroup>
          <optgroup label='東北'>
            <option value='青森県'>青森県</option>
            <option value='岩手県'>岩手県</option>
            <option value='宮城県'>宮城県</option>
            <option value='秋田県'>秋田県</option>
            <option value='山形県'>山形県</option>
            <option value='福島県'>福島県</option>
          </optgroup>
          <optgroup label='関東'>
            <option value='茨城県'>茨城県</option>
            <option value='栃木県'>栃木県</option>
            <option value='群馬県'>群馬県</option>
            <option value='埼玉県'>埼玉県</option>
            <option value='千葉県'>千葉県</option>
            <option value='東京都'>東京都</option>
            <option value='神奈川県'>神奈川県</option>
            <option value='山梨県'>山梨県</option>
            <option value='長野県 '>長野県 </option>
          </optgroup>
          <optgroup label='北陸'>
            <option value='新潟県'>新潟県</option>
            <option value='富山県'>富山県</option>
            <option value='石川県'>石川県</option>
            <option value='福井県'>福井県</option>
          </optgroup>
          <optgroup label='中部'>
            <option value='岐阜県'>岐阜県</option>
            <option value='静岡県'>静岡県</option>
            <option value='愛知県'>愛知県</option>
            <option value='三重県'>三重県</option>
          </optgroup>
          <optgroup label='近畿'>
            <option value='滋賀県'>滋賀県</option>
            <option value='京都府'>京都府</option>
            <option value='大阪府'>大阪府</option>
            <option value='兵庫県'>兵庫県</option>
            <option value='奈良県'>奈良県</option>
            <option value='和歌山県'>和歌山県</option>
          </optgroup>
          <optgroup label='中国'>
            <option value='鳥取県'>鳥取県</option>
            <option value='島根県'>島根県</option>
            <option value='岡山県'>岡山県</option>
            <option value='広島県'>広島県</option>
            <option value='山口県'>山口県</option>
          </optgroup>
          <optgroup label='四国'>
            <option value='徳島県'>徳島県</option>
            <option value='香川県'>香川県</option>
            <option value='愛媛県'>愛媛県</option>
            <option value='高知県'>高知県</option>
          </optgroup>
          <optgroup label='九州'>
            <option value='福岡県'>福岡県</option>
            <option value='佐賀県'>佐賀県</option>
            <option value='長崎県'>長崎県</option>
            <option value='熊本県'>熊本県</option>
            <option value='大分県'>大分県</option>
            <option value='宮崎県'>宮崎県</option>
            <option value='鹿児島県'>鹿児島県</option>
          </optgroup>
          <optgroup label='沖縄'>
            <option value='沖縄県'>沖縄県</option>
          </optgroup>
        </select>
        <p>+</p>
        <select onChange={(e) => setPost(e.target.value)} value={post}>
          <option value=''>雇用形態</option>
          <option value='新卒採用（正社員）'>新卒採用（正社員）</option>
          <option value='新卒採用（契約社員）'>新卒採用（契約社員）</option>
          <option value='中途採用（正社員）'>中途採用（正社員）</option>
          <option value='中途採用（契約社員）'>中途採用（契約社員）</option>
          <option value='アルバイト・パート採用	'>アルバイト・パート採用	</option>
          <option value='業務委託（フリーランス）'>業務委託（フリーランス）</option>
          <option value='インターンシップ'>インターンシップ</option>
        </select>
        <p>=</p>
        <button onClick={() => search()}>検索</button>
      </div> */}

      <div className={Styles.list}>
        {recruitList.length === 0 ?
          <h4 className={Styles.list_none}>指定した条件で美容室を検索しましたが、該当する美容室がございませんでした。</h4>
        : <></> }
        {recruitList.map((data, index) =>
          <div className={Styles.list_box} onClick={() => history.push(`/recruit/id=${data.id}`)}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${data['store_imageName'][0]}_1080x1080?alt=media&token=`}
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImage;
              }}/>
            <div>
              <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${data['companyId']}_200x200?alt=media&token=`}
                onError={e => {
                  e.target.onError = null;
                  e.target.src = DefaultImage;
                }}/>
              <h4>{data['store_name']}</h4>
            </div>
            <h3>{data['store_title']}</h3>
          </div>
        )}
      </div>

      <Footer />

      {modal ?
        <Registration setModal={setModal} />
      : <></> }
      
    </DocumentMeta>
    </>
  );
}

export default WebPortal;
