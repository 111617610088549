import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import Header from './6.header/index';
import Progress1 from './../../../../image/works/edit/1.png';
import Progress2 from './../../../../image/works/edit/2.png';
import Progress3 from './../../../../image/works/edit/3.png';
import { useHistory } from 'react-router-dom';
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Modal from '../../package/parts/pop-modal/index';
import { FaChevronRight } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import AccessImg from './../../../../image/works/edit/access.png';

const WorksEdit = () => {
  
  const history = useHistory();
  
  var link = new URL(window.location).href;

  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  const [ limit, setLimit ] = useState(0);
  const [ modal, setModal ] = useState(0);

  const [ statusCount, setStatusCount ] = useState(0);

  const [ progress, setProgress ] = useState(0);
  const [ progress1, setProgress1 ] = useState(false);
  const [ progress2, setProgress2 ] = useState(false);
  const [ progress3, setProgress3 ] = useState(false);
  const [ progress4, setProgress4 ] = useState(false);
  const [ progress5, setProgress5 ] = useState(false);
  const [ progress6, setProgress6 ] = useState(false);
  const [ progress7, setProgress7 ] = useState(false);
  const [ progress8, setProgress8 ] = useState(false);
  const [ progress9, setProgress9 ] = useState(false);
  const [ progress10, setProgress10 ] = useState(false);

  const [ searchTags, setSearchTags ] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        getCompany(user.uid);
      } else {
        history.push('/company/login')
      }
    });
  }, [company, recruit, history]);

  async function getCompany(uid) {
    await firebase.firestore().collection("companies").doc(company).get()
    .then((doc) => {
      setLimit(doc.data().recruitLimit);
      getRecruit();
      if (!doc.data().member.includes(uid)) {
        history.push('/company/login')
      }
    });
  }

  async function getRecruit() {
    await firebase.firestore().collection("recruits").doc(recruit).get()
    .then((doc) => {
      
      setProgress(doc.data().status === undefined ? 3 : doc.data().status);

      setProgress1(doc.data().status_store === undefined ? false : doc.data().status_store);
      setProgress2(doc.data().status_place === undefined ? false : doc.data().status_place);
      setProgress3(doc.data().status_point === undefined ? false : doc.data().status_point);
      setProgress4(doc.data().status_number === undefined ? false : doc.data().status_number);
      setProgress5(doc.data().status_image === undefined ? false : doc.data().status_image);
      setProgress6(doc.data().status_interview === undefined ? false : doc.data().status_interview);
      setProgress7(doc.data().status_person === undefined ? false : doc.data().status_person);
      setProgress8(doc.data().status_hashTags === undefined ? false : doc.data().status_hashTags);
      setProgress9(doc.data().status_recruit === undefined ? false : doc.data().status_recruit);
      setProgress10(doc.data().status_document === undefined ? false : doc.data().status_document);

      var status_count = 0;

      if (doc.data().status_store === true) {
        status_count = status_count + 1;
      }
      if (doc.data().status_place === true) {
        status_count = status_count + 1;
      }
      if (doc.data().status_point === true) {
        status_count = status_count + 1;
      }
      if (doc.data().status_number === true) {
        status_count = status_count + 1;
      }
      if (doc.data().status_image === true) {
        status_count = status_count + 1;
      }
      if (doc.data().status_interview === true) {
        status_count = status_count + 1;
      }
      if (doc.data().status_person === true) {
        status_count = status_count + 1;
      }
      if (doc.data().status_recruit === true) {
        status_count = status_count + 1;
      }
      if (doc.data().status_hashTags === true) {
        status_count = status_count + 1;
      }
      if (doc.data().status_document === true) {
        status_count = status_count + 1;
      }

      setStatusCount(status_count);

      var hashtag = [];
      var nameSplit = doc.data().store_name.split('');

      hashtag = [
        // ...doc.data().place_stationTags,
        17 <= doc.data().recruit_monthlySalaryStart ? 17 : '',
        18 <= doc.data().recruit_monthlySalaryStart ? 18 : '',
        19 <= doc.data().recruit_monthlySalaryStart ? 19 : '',
        20 <= doc.data().recruit_monthlySalaryStart ? 20 : '',
        21 <= doc.data().recruit_monthlySalaryStart ? 21 : '',
        22 <= doc.data().recruit_monthlySalaryStart ? 22 : '',
        23 <= doc.data().recruit_monthlySalaryStart ? 23 : '',
        24 <= doc.data().recruit_monthlySalaryStart ? 24 : '',
        25 <= doc.data().recruit_monthlySalaryStart ? 25 : '',
        doc.data().recruit_employmentStatus,
        ...doc.data().recruit_hashTag,
        ...nameSplit,
      ];

      hashTagsList(doc.data().place_stores === undefined ? 0 : doc.data().place_stores, hashtag);

    });
  }

  function hashTagsList(ids, list) {

    var tags = [];

    for (var i = 0; i < ids.length; i++) {

      if (i + 1 === ids.length) {
        firebase.firestore().collection("stores").doc(ids[i]).get()
        .then((doc) => {
          var tmp = [];
  
          for (var index = 0; index < doc.data().stationTags.length; index++) {
            tmp.push(doc.data().stationTags[index]);
          }
          for (var index = 0; index < doc.data().pentagonHash.length; index++) {
            tmp.push(doc.data().pentagonHash[index]);
          }
          tmp.push(doc.data().type);
  
          setSearchTags([ ...list, ...searchTags, ...tmp, ...tags]);
        });
      } else {
        firebase.firestore().collection("stores").doc(ids[i]).get()
        .then((doc) => {
          var tmp = [];
  
          for (var index = 0; index < doc.data().stationTags.length; index++) {
            tmp.push(doc.data().stationTags[index]);
          }
          for (var index = 0; index < doc.data().pentagonHash.length; index++) {
            tmp.push(doc.data().pentagonHash[index]);
          }

          tags.push(doc.data().type);
          tags.push(...tmp);
        });
      }

    }
  }

  async function save() {

    var array = [];
    array = [ ...searchTags ];

    var tags = [];
    tags = array.filter(function(s){return s !== '';});

    var recruit_searchTags = {};

    for (var i = 0; i < tags.length; i++) {
      recruit_searchTags[tags[i]] = true;
    }

    if (progress1 && progress2 && progress3 && progress4) {
      await firebase.firestore().collection("recruits").where('companyId', '==', company).get()
      .then(snapShot => {
        var count = 0;
        snapShot.forEach((doc) => {
          if (doc.data().status === 1) {
            count = count + 1;
          }
        });

        if (limit >= count) {
          if (progress === 3) {
            firebase.firestore().collection("recruits").doc(recruit)
            .update({
              status: 2,
              recruit_searchTags : { ...recruit_searchTags },
            })
            .then(() => {
              history.push(`/service/id=${company}+page=2`)
            });
          } else {
            history.push(`/service/id=${company}+page=2`);
          }
        } else {
          setModal(true);
        }
      });
    }
  }

  return (
    <>
    <Header />

    <div className={Styles.box}>

      <h4 className={Styles.store_title}>求人情報の編集</h4>

      <div className={Styles.progress_bar}>
        <img src={AccessImg} style={{marginLeft: `calc(${statusCount - 1} * 10%)`}} alt='' />
        <div>
          <div style={1 <= statusCount ? {} : {backgroundColor: 'white'}}></div>
          <div style={2 <= statusCount ? {} : {backgroundColor: 'white'}}></div>
          <div style={3 <= statusCount ? {} : {backgroundColor: 'white'}}></div>
          <div style={4 <= statusCount ? {} : {backgroundColor: 'white'}}></div>
          <div style={5 <= statusCount ? {} : {backgroundColor: 'white'}}></div>
          <div style={6 <= statusCount ? {} : {backgroundColor: 'white'}}></div>
          <div style={7 <= statusCount ? {} : {backgroundColor: 'white'}}></div>
          <div style={8 <= statusCount ? {} : {backgroundColor: 'white'}}></div>
          <div style={9 <= statusCount ? {} : {backgroundColor: 'white'}}></div>
          <div style={10 <= statusCount ? {} : {backgroundColor: 'white'}}></div>
        </div>
      </div>

      <div className={Styles.store_min_title}>
        <img src={Progress1} alt='' />
        <h5>店舗情報</h5>
      </div>

      <div className={Styles.store_items}>
        <div onClick={() => history.push(`/edit/store/id=${company}+page=2+recruitId=${recruit}`)}>
          <p style={{color: progress1 ? '#AD0200' : '#222222'}}>1.店舗概要</p>
          {progress1 ?
            <FaRegCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
        <div onClick={() => history.push(`/edit/storeSelect/id=${company}+page=2+recruitId=${recruit}`)}>
          <p style={{color: progress2 ? '#AD0200' : '#222222'}}>2.募集店舗</p>
          {progress2 ?
            <FaRegCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>

      <div className={Styles.store_min_title}>
        <img src={Progress2} alt='' />
        <h5>求人情報</h5>
      </div>

      <div className={Styles.store_items}>
        <div onClick={() => history.push(`/edit/point/id=${company}+page=2+recruitId=${recruit}`)}>
          <p style={{color: progress3 ? '#AD0200' : '#222222'}}>1.会社の魅力3選</p>
          {progress3 ?
            <FaRegCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
        <div onClick={() => history.push(`/edit/number/id=${company}+page=2+recruitId=${recruit}`)}>
          <p style={{color: progress4 ? '#AD0200' : '#222222'}}>2.数字で見る</p>
          {progress4 ?
            <FaRegCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>
      <div className={Styles.store_items}>
        <div onClick={() => history.push(`/edit/photo/id=${company}+page=2+recruitId=${recruit}`)}>
          <p style={{color: progress5 ? '#AD0200' : '#222222'}}>3.写真で見る</p>
          {progress5 ?
            <FaRegCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
        <div onClick={() => history.push(`/edit/interview/id=${company}+page=2+recruitId=${recruit}`)}>
          <p style={{color: progress6 ? '#AD0200' : '#222222'}}>4.インタビュー</p>
          {progress6 ?
            <FaRegCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>
      <div className={Styles.store_items}>
        <div onClick={() => history.push(`/edit/person/id=${company}+page=2+recruitId=${recruit}`)}>
          <p style={{color: progress7 ? '#AD0200' : '#222222'}}>5.求める人物像</p>
          {progress7 ?
            <FaRegCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
        <div onClick={() => history.push(`/edit/hashtag/id=${company}+page=2+recruitId=${recruit}`)}>
          <p style={{color: progress8 ? '#AD0200' : '#222222'}}>6.ハッシュタグ</p>
          {progress8 ?
            <FaRegCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>
      <div className={Styles.store_items}>
        <div onClick={() => history.push(`/edit/recruit/id=${company}+page=2+recruitId=${recruit}`)}>
          <p style={{color: progress9 ? '#AD0200' : '#222222'}}>7.求人情報</p>
          {progress9 ?
            <FaRegCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>

      <div className={Styles.store_min_title}>
        <img src={Progress3} alt='' />
        <h5>書類情報</h5>
      </div>

      <div className={Styles.store_items}>
        <div onClick={() => history.push(`/edit/template/id=${company}+page=2+recruitId=${recruit}`)}>
          <p style={{color: progress10 ? '#AD0200' : '#222222'}}>1.応募設定</p>
          {progress10 ?
            <FaRegCheckCircle className={Styles.store_items_icons_red} />
            :
            <FaChevronRight className={Styles.store_items_icons} />
          }
        </div>
      </div>

      <div className={Styles.button}>
        <button onClick={() => history.push(`/service/id=${company}+page=2`)}>戻る</button>
        <button onClick={() => save()} 
          style={progress1 && progress2 && progress3 && progress4 && progress5 && progress6 && progress7 && progress8 && progress9 && progress10 ? { backgroundColor: '#AD0200', color: 'white' } : { backgroundColor: 'white', color: '#EBEBEB' }}>募集開始</button>
      </div>

    </div>

    {modal ?
      <Modal 
        title={'求人数の制限'}
        desc={`本求人の募集を開始するためには、別途求人広告へのお申し込みが必要となります。新規や追加での広告掲載をご希望される方は、上部の問い合わせか弊社担当者を通じてのご連絡をお願いいたします。`}
        setError={setModal}
      />
    : <></> }

    </>
  );
}

export default WorksEdit;
