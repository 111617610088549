import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useHistory } from 'react-router-dom';
import { IoPersonSharp } from "react-icons/io5";
import { RiLogoutBoxRLine } from "react-icons/ri";
import Icon from './../../../../../image/works/icon.png';
import AccountEdit from '../../../0.header/accountEdit/index';
import firebase from "firebase/compat/app";
import DefaultImage from "./../../../../../image/works/default/account.png";

const MemoriiWorksHeader = () => {

  const history = useHistory();

  const [ userList, setUserList ] = useState('');
  const [ accountSwitch, setAccountSwitch ] = useState(false);
  const [ accountEdit, setAccountEdit ] = useState(false);

  useEffect(() => {
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection("users").doc(user.uid).get()
        .then((doc) => {
          setUserList({...doc.data(), id: doc.id});
        });
      } else {
        history.push('/select')
      }
    })
  }, [history]);

  async function logOut() {
    try {
      const auth = getAuth()
      await signOut(auth).then(() => history.push('/company/login'))
    } catch (e) {}
  }

  return (
    <>
    <div className={Styles.header}>

      <div className={Styles.header_logo}>
        <img src={Icon} alt='memorii works' />
        <h1>memorii works</h1>
      </div>
      
      <div className={Styles.header_right}>
        <div>
          <IoMdHelpCircleOutline className={Styles.header_right_icon} />
          <p>ヘルプ</p>
        </div>
        <div>
          <IoMailOutline className={Styles.header_right_icon} />
          <p>問い合わせ</p>
        </div>
        <div className={Styles.header_right_account}>
          <div className={Styles.header_right_account_info} onClick={() => setAccountSwitch(!accountSwitch)}>
            {userList.length === 0 ? <></> :
              <img className={Styles.header_right_image} alt=''
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${userList.id}_200x200?alt=media&token=?${new Date().getTime()}`}
              // https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F5gVITSENYNZQOTtykdtAMbxBRvF2_200x200?alt=media&token=?1724161561426
              onError={e => {
                console.log(`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F'${userList.id}_200x200?alt=media&token=?${new Date().getTime()}`)
                e.target.onError = null;
                e.target.src = DefaultImage;
              }}/>
            }
          </div>

          {/* 詳細パネル */}
          {accountSwitch ?
            <div className={Styles.header_right_account_nav}>
              <div onClick={() => setAccountEdit(true)}>
                <IoPersonSharp className={Styles.header_right_account_nav_icon} />
                <p>アカウント</p>
              </div>
              <div>
                <RiLogoutBoxRLine className={Styles.header_right_account_nav_icon} />
                <p onClick={() => logOut()}>ログアウト</p>
              </div>
            </div>
          : <></> }

        </div>
      </div>
    </div>

    {accountEdit ?
      <AccountEdit setAccountEdit={setAccountEdit} userList={userList} />
    : <></> }
    </>
  );
}

export default MemoriiWorksHeader;
